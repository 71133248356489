import React, { useContext, useState, useEffect } from "react";
import styled from "styled-components";
import InputShipping from "../InputShipping";
import { CartContext } from "../../context/cart";
import pinIcon from '../../images/svg/pinIcon.svg'
import houseIcon from '../../images/svg/houseIcon.svg'
import chatIcon from '../../images/svg/chatIcon.svg'
import cityIcon from '../../images/svg/cityIcon.svg'
import stateIcon from '../../images/svg/stateIcon.svg'
import { UserContext } from "../../context/user";
import axios from "axios";
import api from "../../services/api";

const Card = styled.div`
  transition: all 0.3s;
  display: flex;
  flex-direction: column;
  width: 359px;
  height:auto;
  background-color:${(props) => (props.active ? "#fbfbfb" : " white")};
  border: 1px solid #e0dcdc;
  border-radius: 5px;
  box-shadow: 0 0 2px #bbbbbbdd;
  @media screen and (max-width:820px){
    margin-top: 1.5rem;
    padding:0.5rem;
    width: 97%;
  }

`;
const CardHeader = styled.div`
  display: flex;
  padding-top: 0.9rem;
  padding-left: 0.5rem;
  justify-content: space-between;
  height: 40px;

  a {
    text-decoration: solid !important;
    cursor: pointer;
  }

  @media screen and (max-width:820px){
    a{
      display: none;
    }
    
  }

`;
const CardBody = styled.div`
  padding-left: 0.5rem;

`
const Row = styled.div`
  display: flex;
  
  p{
    font-family: "Lato", serif;
    font-weight: 700;
    font-style: normal;
  }

  @media screen and (max-width:820px){
    .textType{
      margin-left: 0.5rem;
    }
  }

`;

const CardIndex = styled.div` 
  transition: all .2s;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 100px;
  width: 25px;
  height: 25px;
  padding-top: 0.9rem;
  background-color: ${(props) => (props.active ? "#008d24" : "white")};

  border:${(props) => (props.active ? "none" : "2px solid #cecccc;")};

  p {
    font-size:${(props) => (props.active ? "0.9rem" : "0.8rem")};
    font-family: "Lato", serif;
    font-weight: 700;
    font-style: normal;
    color:${(props) => (props.active ? "white" : "#cecccc")} ;
  }
`;
const ButtonContinue = styled.button`
    margin: 0 auto;
    position: relative;
    border-radius: 2px;
    font-family: "Lato", serif;
    font-weight: 500;
    color: #FFFFFF;
    width: 60%;
    height: 37px;
    background-color: #000000;
    border: none;
    transition: all 0.1s ease-in-out;

    :active {
        top: 13px;
        box-shadow: 0 2px 0px #027a02;
    }

    @media screen and (max-width: 820px) {
        margin-bottom: 1rem;
    }
`;

const ContainerButton = styled.div`
  display:flex;
  justify-content: center;
  align-items: center;

`

const CardConfirmAddress = ({ name, index, id, activeId, setActiveId, idSelect, onClick }) => {

  const { setSelectInfo, selectInfo, handleFreight, handleFreightPayment } = useContext(CartContext)
  const { setZipCode, setStreet, setLocation, zipCode, street, location, setNumber, number, state, setState, setZipCodeErr, zipCodeErr, setSelectOption } = useContext(UserContext)
  const [adressErr, setAdressErr] = useState()
  const [numberErr, setNumberErr] = useState()
  const [zipCodeErrMessage, setZipCodeErrMessage] = useState()
  const [data, setData] = useState([])
  const [neighborhood, setNeighborhood] = useState()


  const getCep = async () => {
    const cleanedCep = zipCode.replace(/\D/g, ""); // Remove caracteres não numéricos

    if (cleanedCep.length !== 8) {
      setZipCodeErrMessage("CEP inválido!");
      setZipCodeErr(true);
      return;
    }

    try {
      const response = await axios.get(`https://viacep.com.br/ws/${cleanedCep}/json/`);
      if (response.status === 200 && !response.data.erro) {
        handleFreight(zipCode)
        setZipCodeErrMessage("");
        localStorage.setItem("@ZIP_CODE", cleanedCep)
        setStreet(response.data.logradouro || "");
        setState(response.data.uf || "");
        setLocation(response.data.localidade || "");
        setNeighborhood(response.data.bairro)

        setZipCodeErr(false);
      } else {
        setZipCodeErrMessage("CEP não encontrado!");
        setZipCodeErr(true);
      }
    } catch (error) {
      setZipCodeErrMessage("Erro ao buscar CEP! Tente novamente.");
      setZipCodeErr(true);
    }
  };
  const getTypeDeliveryCep = async (zipCode) => {
    const cleanedCep = zipCode.replace(/\D/g, ""); // Remove caracteres não numéricos

    if (cleanedCep.length !== 8) {
      setZipCodeErrMessage("CEP inválido!");
      setZipCodeErr(true);
      return;
    }

    try {
      const response = await axios.get(`https://viacep.com.br/ws/${cleanedCep}/json/`);
      if (response.status === 200 && !response.data.erro) {
        setZipCodeErrMessage("");
        localStorage.setItem("@ZIP_CODE", cleanedCep)
        setStreet(response.data.logradouro || "");
        setState(response.data.uf || "");
        setLocation(response.data.localidade || "");
        setZipCodeErr(false);
      } else {
        setZipCodeErrMessage("CEP não encontrado!");
        setZipCodeErr(true);
      }
    } catch (error) {
      setZipCodeErrMessage("Erro ao buscar CEP! Tente novamente.");
      setZipCodeErr(true);
    }
  };
  const getAddress = async () => {
    const response = await api.get(`addresses/get`);
    if (response.status == 404) {
      setData([])
      return false
    }
    setData(response.data)

  }

  useEffect(() => {
    if (zipCode == "") {
      setZipCodeErrMessage("Informe um cep!")
      setZipCodeErr(true)
    } else {
      setZipCodeErrMessage("")
      setZipCodeErr(false)
    }

    if (street == "") {
      setAdressErr(true)
    } else {
      setAdressErr(false)
    }

    if (number == "") {
      setNumberErr(true)
    } else {
      setNumberErr(false)
    }

  }, [zipCode, street, number])

  useEffect(() => {
    getAddress()
  }, [])
  const storageAddress = async () => {
    const response = await api.get(`addresses/get`);
    if (response.status == 200) {
      setZipCode(response.data[0].zipCode)
      setNumber(response.data[0].number)
      setStreet(response.data[0].street)
      setLocation(response.data[0].city)
      setState(response.data[0].state)

      const responseCep = await axios.get(`https://viacep.com.br/ws/${response.data[0].zipCode}/json/`);
      setNeighborhood(responseCep.data.bairro)
    }
  }

  useEffect(() => {
    storageAddress()
  }, [])


  return (
    <Card className="card_secondary">
      <CardHeader>
        <Row>
          <CardIndex active={selectInfo == 2 ? true : false}  >
            <p>{index}</p>
          </CardIndex>
          <p className="textType">{name}</p>
        </Row>
        <a onClick={() => { setSelectInfo(2); setSelectOption() }} >Editar</a>
      </CardHeader>
      {selectInfo == 2

        ?
        <CardBody>
          <div class="dropdown">
            <button class="btn btn-primary  btn-sm dropdown-toggle" style={{ background: "none", color: "black", border: "1px solid black", fontSize: "0.8rem" }} type="button" data-bs-toggle="dropdown" aria-expanded="false">
              Meus Endereços
            </button>
            <ul class="dropdown-menu">
              {data?.map(item => {
                return (
                  <li onClick={() => { handleFreightPayment(item.zipCode); setZipCode(item.zipCode); getTypeDeliveryCep(item.zipCode); setNumber(item.number) }} style={{ color: "white", fontSize: "1rem", marginLeft: "0.5rem", fontFamily: "Lato", cursor: "pointer" }} >{item.street} - {item.number}</li>

                )
              })}
            </ul>
          </div>
          <InputShipping err={zipCodeErr} messageErr={zipCodeErrMessage} mask={"99999-99999"} name="CEP (0000-0000)" value={zipCode} onChange={(e) => setZipCode(e.target.value)} image={houseIcon} onBlur={(e) => { getCep(e.target.value); handleFreight(e.target.value) }} />
          <InputShipping err={adressErr} name="Endereço (Rua,Avenida,Quadra,Lote.)" value={street} onChange={(e) => setStreet(e.target.value)} image={pinIcon} />
          <InputShipping err={adressErr} name="Bairro" value={neighborhood} onChange={(e) => setNeighborhood(e.target.value)} image={pinIcon} />

          <InputShipping mask={"9999"} err={numberErr} messageErr={"Informe um número"} name="Número" value={number} image={chatIcon} onChange={(e) => setNumber(e.target.value)} />
          <InputShipping name="Cidade" value={location} image={cityIcon} />
          <InputShipping name="Estado" value={state} image={stateIcon} />
          <ContainerButton>
            <ButtonContinue onClick={() => setSelectInfo(4)}>Continuar</ButtonContinue>
          </ContainerButton>
        </CardBody>
        :

        <></>

      }


    </Card>
  );
};



export default CardConfirmAddress;
