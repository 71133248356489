import React, { useContext, useState, useEffect } from 'react'
import styled from 'styled-components'
import { CartContext } from '../../context/cart'
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import { useNavigate } from 'react-router-dom'
import { UserContext } from '../../context/user'

const Card = styled.div`
    margin-left: 1rem;
    transition: all .3s;
    display: flex;
    flex-direction: column;
    width: ${(props) => (props.active ? " 359px" : "220px")};
    overflow-y: ${(props) => (props.active ? " auto" : "hidden")};
    height: 120px;
    background-color:white ;
    height: ${(props) => (props.active ? "auto" : "50px")};
    background-color:${(props) => (props.active ? "#fbfbfb" : " white")};
    border: 1px solid #e0dcdc;
    box-shadow: 0 0 2px #bbbbbbdd;

    .textPrimary{
    font-size: 0.8rem;
    }

    p{

    font-family: "Lato", serif;
    font-weight: 700;
    font-style: normal;
  }

  .details_text{
    margin-bottom: 1.9rem;
  }

`
const CardBody = styled.div`
   display:${(props) => (props.active ? "block" : "none")} ;

   padding-left: 0.9rem;

   .textTotal{
    font-size: 1.0rem;
    font-family: "Lato", serif;
    font-weight: 700;
    font-style: normal;
   }

   .textPrimary{
    font-size: 0.9rem;
    font-family: "Lato", serif;
    font-weight: 600;
    color:#4c4c4c;
    font-style: normal;
    height: 10px;
   }
   .textSecondary{
    font-size: 0.9rem;
    font-family: "Lato", serif;
    font-weight: 600;
    color:#4c4c4c;
    font-style: normal;
    height: 10px;
   }


`
const CardHeader = styled.div`
    display:flex;
    height:${(props) => (props.active ? "40px" : "10px")};
    
    padding-top: 0.9rem;
    padding-left: 0.5rem;
    overflow-y: none;
    justify-content: space-between;
    
    a{
        text-decoration: solid !important;
        cursor: pointer;
    }

    @media screen and (max-width:820px){
        margin-bottom: 1.0rem;
    }
    
`
const CardIndex = styled.div` 
  transition: all .2s;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 100px;
  width: 25px;
  height: 25px;
  padding-top: 0.9rem;
  background-color: ${(props) => (props.active ? "#008d24" : "white")};

  border:${(props) => (props.active ? "none" : "2px solid #cecccc;")};

  p {
    font-size:${(props) => (props.active ? "0.9rem" : "0.8rem")};
    font-family: "Lato", serif;
    font-weight: 700;
    font-style: normal;
    color:${(props) => (props.active ? "white" : "#cecccc")} ;
  }
`;
const RowProduct = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    height: 55px;

        .textPrimary{  
            font-family: "Lato", serif;
            font-weight: 500;
            text-align: center;
            margin-left: 0.3rem;
            font-size: 0.7rem;
        }

        .textSecondary{  
            font-family: "Lato", serif;
            font-weight: 700;
            text-align: center;
            margin-left: 0.3rem;
            font-size: 0.8rem;
        }

`
const ContainerImage = styled.div`
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50px;
    height: 50px;
    border: 1px solid #bcbbbb;
    border-radius: 100%;

`
const Image = styled.img`
   
   object-fit: contain;
   width: 39px;
   height: 39px;


`
const QuantityProduct = styled.div`
    position: absolute;
    display: flex;
    top: -12px;
    right: -25px;
    justify-content: center;
    align-items: center;
    width: 20px;
    height: 20px;
    border-radius:100px;
    color: white;
    background-color: black;

    p{
    position: relative;
    top: 7px;
    font-size: 0.7rem;
    font-family: "Lato", serif;
    font-weight: 700;
    font-style: normal;
    }


`
const RowTotal = styled.div`

    display: flex;
    justify-content: flex-start;


`
const CardSkeleton = styled.div`
    width: 120px;
`
const CardResum = styled.div`
    display: flex;
    justify-content: space-between;
    height: 9px;


`
const Line = styled.div`
    width: 100%;
    height: 1px;
    background-color: black;
    border-bottom: 1px solid black;

`
const Row = styled.div`
    display: flex;


    p{
    font-size: ${(props) => (props.active ? "1rem" : "1rem")};
    font-family: "Lato", serif;
    font-weight: 700;
    font-style: normal;
  }

  .textType{
      position: relative;
      margin-left:${(props) => (props.active ? "  0.5rem" : " -0.3rem")};
      top:${(props) => (props.active ? "" : " 2px")};

    }

`
const Button = styled.div`
    cursor: pointer;
    display:flex;
    justify-content: center;
    align-items: center;
    border-radius: 2px;
    font-family: "Lato", serif;
    font-weight: 500;
    color:black;
    width: 150px;
    height: 30px;
    border: 1px solid black;
    background-color: none;
    border: none;

    margin-top: 0rem;

`

const ContainerButtonsFlex = styled.div`
  margin-top: 2.3rem;
  display:flex;
  justify-content: space-between;

  

`

const ButtonContinue = styled.button`
    
    border-radius: 2px;
    font-family: "Lato", serif;
    font-weight: 500;
    color: #FFFFFF;
    width: 40%;
    height: 35px;
    background-color: #000000;
    border: none;
    transition: all 0.1s ease-in-out;

    :active {
        top: 13px;
        box-shadow: 0 2px 0px #027a02;
    }

    @media screen and (max-width: 820px) {
        margin-bottom: 1rem;
    }
`;

const CardConfirmPaymentRequestInfo = () => {
    const navigate = useNavigate()
    const { cart, price, priceAddress, animation, selectInfo, setSelectInfo } = useContext(CartContext)
    const [totalPrice, setTotalPrice] = useState()
    const { street } = useContext(UserContext)

    useEffect(() => {

        const formmatedPrice = String(price).replace(",", ".")
        const formmatedPriceAddress = String(priceAddress).replace(",", ".")
        const total = Number(formmatedPrice) + Number(formmatedPriceAddress)
        setTotalPrice(total)

    }, [priceAddress])
    return (

        <Card className='card_shipping' active={selectInfo == 4 ? true : false} >
            <CardHeader active={selectInfo == 4 ? true : false}>
                <Row active={selectInfo == 4 ? true : false}>
                    <CardIndex active={selectInfo == 4 ? true : false}  >
                        <p>3</p>
                    </CardIndex>
                    <p className="textType">Detalhes do pedido</p>
                </Row>
                {/* <a onClick={() => { setSelectInfo(2); setSelectOption() }} >Editar</a> */}
            </CardHeader>
            <CardBody active={selectInfo == 4 ? true : false}>
                {cart?.map(item => {
                    console.log(item)
                    const splitText = String(item.name).split(" ")
                    const formmatedSplitText = `${splitText[0]} ${splitText[1]} ${splitText[2]} `
                    return (
                        <RowProduct>
                            <ContainerImage>
                                <Image src={item.product_image} />
                                <QuantityProduct>
                                    <p>{item.quantity}</p>
                                </QuantityProduct>
                            </ContainerImage>
                            <p className='textPrimary'>{formmatedSplitText}...</p>
                            <p className='textSecondary'>R$ {Number(item.promotion_price).toLocaleString("pt-BR", { minimumFractionDigits: 2 })}</p>

                        </RowProduct>
                    )
                })}
                {animation
                    ?
                    <CardSkeleton>
                        <Skeleton />
                        <Skeleton />
                        <Skeleton />
                    </CardSkeleton>
                    :
                    <>
                        <CardResum>
                            <p className='textTotal'>Resumo do pedido:</p>
                            <p className='textPrimary' >{Number(price).toLocaleString("pt-BR", { minimumFractionDigits: 2 })}</p>
                        </CardResum>
                        <Line />
                        <CardResum>
                            <p className='textPrimary'>Subtotal:</p>
                            <p className='textPrimary' >{Number(price).toLocaleString("pt-BR", { minimumFractionDigits: 2 })}</p>
                        </CardResum>
                        <Line />
                        <CardResum>
                            <p className='textSecondary'>Frete:</p>
                            <p className='textSecondary'>{Number(priceAddress).toLocaleString("pt-BR", { minimumFractionDigits: 2 })}</p>
                        </CardResum>
                        <Line />

                        <CardResum>
                            <p className='textTotal'>Total do pedido:</p>
                            <p className='textTotal'>R$ {Number(totalPrice).toLocaleString("pt-BR", { minimumFractionDigits: 2 })}</p>
                        </CardResum>
                        {street

                            ?
                            <ContainerButtonsFlex>
                                <ButtonContinue onClick={() => setSelectInfo(3)}>Continuar</ButtonContinue>
                                <p style={{ cursor: "pointer", position: "relative", top: "7px" }}>Adicionar Produto </p>
                            </ContainerButtonsFlex>
                            :

                            <></>

                        }

                    </>

                }


            </CardBody>
            <RowTotal>

            </RowTotal>


        </Card>
    )
}

export default CardConfirmPaymentRequestInfo