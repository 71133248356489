import React, { createContext, useEffect, useRef, useState } from 'react';

export const UserContext = createContext({});

function UserProvider({ children }) {

    const [zipCode, setZipCode] = useState()
    const [street, setStreet] = useState()
    const [location, setLocation] = useState()
    const [number, setNumber] = useState()
    const [state, setState] = useState()
    const [selectOption,setSelectOption] = useState()
 
    const [zipCodeErr,setZipCodeErr] = useState()



    return (
        <UserContext.Provider value={{
            setZipCode,
            zipCode,
            setStreet,
            street,
            setLocation,
            location,
            setNumber,
            number,
            setState,
            state,
            setZipCodeErr,
            zipCodeErr,
            setSelectOption,
            selectOption
        }}>
            {children}
        </UserContext.Provider>
    );
}

export default UserProvider;
