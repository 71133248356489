import React, { useContext, useState, useEffect } from "react";
import styled from "styled-components";
import InputShipping from "../InputShipping";
import { CartContext } from "../../context/cart";
import pinIcon from '../../images/svg/pinIcon.svg'
import houseIcon from '../../images/svg/houseIcon.svg'
import chatIcon from '../../images/svg/chatIcon.svg'
import cityIcon from '../../images/svg/cityIcon.svg'
import stateIcon from '../../images/svg/stateIcon.svg'
import { PaymentContext } from "../../context/paymet";
import { UserContext } from "../../context/user";
import axios from "axios";
import api from "../../services/api";

const ButtonContinue = styled.button`
    
    @media screen and (max-width:820px){

      border-radius: 2px;
    font-family: "Lato", serif;
    font-weight: 500;
    color:#FFFFFF;
    width: 100%;
    height: 43px;
    background-color: #000000;
    border: none;      
    }


`
const Card = styled.div`
  transition: all 0.3s;
  display: flex;
  flex-direction: column;
  width: 359px;
  height:520px;
  background-color:${(props) => (props.active ? "white" : " #fbfbfb")};
  border: 1px solid #e0dcdc;

  @media screen and (max-width:820px){
    margin-top: 1.5rem;
    padding:0.5rem;
    width: 97%;
  }

`;

const CardHeader = styled.div`
  display: flex;
  padding-top: 0.9rem;
  padding-left: 0.5rem;
  justify-content: space-between;
  height: 35px;

  a {
    text-decoration: solid !important;
    cursor: pointer;
  }

  @media screen and (max-width:820px){
    a{
      display: none;
    }
    
  }

`;

const CardBody = styled.div`
  padding-left: 0.5rem;

`

const Row = styled.div`
  display: flex;
  
  p{
    font-family: "Lato", serif;
    font-weight: 700;
    font-style: normal;
  }

  @media screen and (max-width:820px){
    .textType{
      margin-left: 0.5rem;
    }
  }

`;

const CardIndex = styled.div` 
  transition: all .2s;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 100px;
  width: 25px;
  height: 25px;
  padding-top: 0.9rem;
  background-color: #008d24 ;

  border:${(props) => (props.active ? "none" : "2px solid #cecccc;")};

  p {
    font-size:${(props) => (props.active ? "0.9rem" : "0.8rem")};
    font-family: "Lato", serif;
    font-weight: 700;
    font-style: normal;
    color:white ;
  }
`;

const CardConfirmAddressMobile = ({ name, index, id, activeId, setActiveId, idSelect, onClick }) => {
  const {
    setAddress,
    getZipCode,
    address

  } = useContext(PaymentContext)
  const isActive = activeId === id;
  const { setSelectInfo, selectInfo, priceAddress } = useContext(CartContext)
  const { setZipCode, setStreet, setLocation, zipCode, street, location, setNumber, number, state, setState } = useContext(UserContext)
  const [zipCodeErr, setZipCodeErr] = useState()
  const [neighborhood,setNeighborhood] = useState()
  const [adressErr, setAdressErr] = useState()
  const [neighborhoodErr, setNeighborhoodErr] = useState()

  const [numberErr, setNumberErr] = useState()
  const [zipCodeErrMessage, setZipCodeErrMessage] = useState()
  const [btnDisable, setBtnDisable] = useState()
  const [data, setData] = useState([])


  const getCep = async () => {
    const cleanedCep = zipCode.replace(/\D/g, ""); // Remove caracteres não numéricos

    if (cleanedCep.length !== 8) {
      setZipCodeErrMessage("CEP inválido!");
      setZipCodeErr(true);
      return;
    }

    try {
      const response = await axios.get(`https://viacep.com.br/ws/${cleanedCep}/json/`);

      if (response.status === 200 && !response.data.erro) {
        localStorage.setItem("@ZIP_CODE", cleanedCep)
        setZipCodeErrMessage("");
        setAddress(response.data.logradouro || "");
        setState(response.data.uf || "");
        setLocation(response.data.localidade || "");
        setNeighborhood(response.data.bairro)
        setZipCodeErr(false);
        setBtnDisable(true)

      } else {
        setBtnDisable(true)
        setZipCodeErrMessage("CEP não encontrado!");
        setZipCodeErr(true);
      }
    } catch (error) {
      setZipCodeErrMessage("Erro ao buscar CEP! Tente novamente.");
      setZipCodeErr(true);
      setBtnDisable(true)

    }
  };
  const getLocalCep = async (zipCode) => {
    const cleanedCep = zipCode.replace(/\D/g, ""); // Remove caracteres não numéricos

    if (cleanedCep.length !== 8) {
      setZipCodeErrMessage("CEP inválido!");
      setZipCodeErr(true);
      return;
    }

    try {
      const response = await axios.get(`https://viacep.com.br/ws/${cleanedCep}/json/`);

      if (response.status === 200 && !response.data.erro) {
        localStorage.setItem("@ZIP_CODE", cleanedCep)
        setZipCodeErrMessage("");
        setAddress(response.data.logradouro || "");
        setState(response.data.uf || "");
        setLocation(response.data.localidade || "");
        setNeighborhood(response.data.bairro)

        setZipCodeErr(false);
        setBtnDisable(true)

      } else {
        setBtnDisable(true)
        setZipCodeErrMessage("CEP não encontrado!");
        setZipCodeErr(true);
      }
    } catch (error) {
      setZipCodeErrMessage("Erro ao buscar CEP! Tente novamente.");
      setZipCodeErr(true);
      setBtnDisable(true)

    }
  };
  const storage = () => {

    if (!zipCode) {
      setZipCodeErr(true)
      return false
    } else {
      setZipCodeErr(false)
    }

    if (!address) {
      setAdressErr(true)
      return false
    } else {
      setAdressErr(false)
    }

    if (!number) {
      setNumberErr(true)
      return false

    } else {
      setNumberErr(false)
    }
    setSelectInfo(3)

  }
  const getAddress = async () => {
    const response = await api.get(`addresses/get`);
    if (response.status == 404) {
      setData([])
      return false
    }
    setData(response.data)

  }

  const storageAddress = async () => {
    const response = await api.get(`addresses/get`);
    if(response.status == 200){
      setZipCode(response.data[0].zipCode)
      setNumber(response.data[0].number)
      setStreet(response.data[0].street)
      setAddress(response.data[0].street)
      setLocation(response.data[0].city)
      setState(response.data[0].state)

      const responseCep = await axios.get(`https://viacep.com.br/ws/${response.data[0].zipCode}/json/`);
      setNeighborhood(responseCep.data.bairro)

    }
  }

  useEffect(() => {
    storageAddress()
  },[])


  useEffect(() => {
    if (localStorage.getItem("@ZIP_CODE")) {
      getLocalCep(localStorage.getItem("@ZIP_CODE"))
    }
  }, [])


  useEffect(() => {
    getAddress()
  }, [])


  useEffect(() => {
    if (zipCode == "") {
      setZipCodeErr(true)
    } else {
      setZipCodeErr(false)
    }

    if (address == "") {
      setAdressErr(true)
    } else {
      setAdressErr(false)
    }

    if (number == "") {
      setNumberErr(true)
    } else {
      setNumberErr(false)
    }

  }, [zipCode, street, number])


  return (
    <Card className="card_secondary">
      <CardHeader>
        <Row>
          <CardIndex >
            <p>{index}</p>
          </CardIndex>
          <p className="textType">{name}</p>
        </Row>
        <a >Editar</a>
      </CardHeader>
      {selectInfo == 2

        ?
        <CardBody>
          <div class="dropdown">
            <button class="btn btn-primary  btn-sm dropdown-toggle" style={{ background: "none", color: "black", border: "1px solid black" }} type="button" data-bs-toggle="dropdown" aria-expanded="false">
              Meus Endereços
            </button>
            <ul class="dropdown-menu">
              {data?.map(item => {
                return (
                  <li onClick={() => { getLocalCep(item.zipCode); setNumber(item.number);setZipCode(item.zipCode) }} style={{ color: "white", fontSize: "1rem", marginLeft: "0.5rem", fontFamily: "Lato", cursor: "pointer" }} >{item.street} - {item.number}</li>

                )
              })}
            </ul>
          </div>
          <InputShipping err={zipCodeErrMessage ? true : false} messageErr={zipCodeErrMessage} mask={"99999-99999"} name="CEP (0000-0000)" value={zipCode} onChange={(e) => setZipCode(e.target.value)} image={houseIcon} onBlur={(e) => getCep(e.target.value)} />
          <InputShipping err={adressErr} messageErr="Informe um endereço" name="Endereço (Rua,Avenida,Quadra,Lote.)" value={address} onChange={(e) => setAddress(e.target.value)} image={pinIcon} />
          <InputShipping err={adressErr} messageErr="Informe um bairro" name="Bairro" value={neighborhood} onChange={(e) => setNeighborhood(e.target.value)} image={pinIcon} />

          <InputShipping mask={"9999"} err={numberErr} messageErr={"Informe um número"} name="Número" value={number} image={chatIcon} onChange={(e) => setNumber(e.target.value)} />
          <InputShipping name="Cidade" value={location} image={cityIcon} />
          <InputShipping name="Estado" value={state} image={stateIcon} />
        </CardBody>
        :

        <></>

      }

      <ButtonContinue onClick={() => storage()}>
        Continuar
      </ButtonContinue>

    </Card>
  );
};



export default CardConfirmAddressMobile;
