import React, { useState, useEffect, useContext } from 'react'
import FilterProduct from '../../components/FilterProduct'
import Card from '../../components/NewCard'
import { FilterProductContext } from '../../context/filterProduct'
import columIcon from './images/svg/columIcon.svg'
import orderIcon from './images/svg/orderIcon.svg'
import listIcon from './images/svg/listIcon.svg'
import './styles/style.css'
import notFoundIcon from './images/svg/notFound.svg'
import { useLocation } from 'react-router-dom';
import FilterProducts from '../../components/FilterProducts'
import { motion } from 'framer-motion'
import Container from '../../components/Container'
import Header from '../../components/Header'
import SidebarFilterProduct from '../../components/SidebarFilterProduct'
import HeaderMobile from '../../components/HeaderMobile'
import HeaderSecondary from '../../components/HeaderSecondary'
import SidebarCart from '../../components/SidebarCart'
import api from '../../services/api'
import SidebarFilter from '../../components/SidebarFilterTest';
import { SidebarContext } from '../../context/sidebar'
import { CartContext } from '../../context/cart'
const ProductsPage = () => {
    const { handleAddToCart } = useContext(CartContext);
    const { handleSidebar } = useContext(SidebarContext)

    const location = useLocation()
    const queryParams = new URLSearchParams(location.search);
    const category = queryParams.get('category');
    const { inputSidebar } = useContext(FilterProductContext)

    const [page, setPage] = useState(1); // Estado para a página
    const [loading, setLoading] = useState(false)
    const [data, setData] = useState([])
    const [mobile, setMobile] = useState()


    const getAll = async (page, category) => {
        setLoading(true);
        
        try {
            const response = await api.get(`client/get/all/products/category/pagination/?page=${page}&category=${category}`);
            if (response.status === 200) {
                if (response.data.products.length > 0) {
                    setData(prevCategorys => {
                        const newProducts = response.data.products.filter(newProduct =>
                            !prevCategorys.some(existingProduct => existingProduct.id === newProduct.id)
                        );
                        return [...prevCategorys, ...newProducts];
                    });
                }
                // Se não houver produtos, não faz nada para não apagar os existentes
            } else {
                // Em caso de erro, não apaga os produtos
                console.log('Erro na resposta');
            }
        } catch (error) {
            console.error('Erro ao buscar produtos:', error);
        } finally {
            setLoading(false);
        }
    };
    useEffect(() => {
        getAll(page, category);
    }, [page, category]);


    function handleResize() {
        if (window.innerWidth <= 712) {
            setMobile(false,category)
            handleSidebar(false)

        } else {
            setMobile(false)
            handleSidebar(true,category)

        }
    }

    useEffect(() => {
        function handleResize() {
            if (window.innerWidth <= 712) {
                setMobile(false)
                handleSidebar(true)
            } else {
                setMobile(false)
                handleSidebar(true)

            }
        }

        // Adiciona o event listener
        window.addEventListener('resize', handleResize);

        // Remove o event listener ao desmontar o componente
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    useEffect(() => {

        handleResize()
    }, [])


    useEffect(() => {
        handleSidebar(true,category)
        handleResize()

    },[category])


    useEffect(() => {
        const observer = new IntersectionObserver((entries) => {
            if (entries.some(entry => entry.isIntersecting)) {
                setPage(prevPage => prevPage + 1);
            }
        });

        const sentinela = document.querySelector("#sentinela");
        if (sentinela) observer.observe(sentinela);

        return () => {
            if (sentinela) observer.unobserve(sentinela);
        };
    }, []); // <-- Remova `page` das dependências



    return (

        <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
        >
            <Header />
            <SidebarCart />
            <HeaderMobile />
            <HeaderSecondary />
            <SidebarFilter mobile={mobile} />




            <section className='container_flex_products'>



                <div className='colum_primary_flex_products'>
                    <SidebarFilterProduct />
                </div>

                <div className='colum_secondary_flex_products'>

                    <div className='flex_filter_data'>
                        <FilterProduct type="product" name="Ordem" id={"5"} image={orderIcon} />
                        <FilterProduct type="product" name="Coluna" id={"0"} image={columIcon} />
                    </div>

                    <div className='container_cards_data'>
                        {
                            data?.map(item => {
                                return (

                                    <Card onClick={() => handleAddToCart(item)} weight={item.weight} name={item.name} promotion_price={item.promotion_price} product_id={item.product_id} price={item.price} description={item.description} product_image={item.product_image} category_image={item.category_image} />
                                )
                            })
                        }
                        <div id="sentinela" style={{ width: "120px", height: "320px" }}></div>

                    </div>

                </div>

            </section>



            <Container/>
        </motion.div>


    )
}

export default ProductsPage