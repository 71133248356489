import React, { useState, useRef } from 'react';
import styled from 'styled-components';
import InputMask from 'react-input-mask';

const Card = styled.div`
  width: 100%;
  position: relative;

  img {
    position: absolute;
    top: 5px;
    width: 16px;
    height: 16px;
  }

  input {
    width: 100%;
    padding-left: 1.5rem;
    background-color: transparent;
    border: none;
    border-bottom: 1px solid #dddddd;
    outline: none;
  }

  @media screen and (max-width:820px){
    label {
    position: absolute;
    left: 25px;
    transition: all 0.2s ease-in-out;
    color: #999;
    cursor: pointer;
  }
  }

  label {
    position: absolute;
    left: 25px;
    transition: all 0.2s ease-in-out;
    color: #999;
    cursor: pointer;
  }

  .toTop {
    top: -15px;
    left: 15px;
    font-size: 0.8rem;
    color: #333;
  }
`;

const InputShipping = ({ name, image, onChange, value, nameInput, onFocus, mask, type,onBlur,err,messageErr }) => {
  const [inputFocus, setInputFocus] = useState(false);
  const inputRef = useRef(null);

  const handleLabelClick = () => {
    setInputFocus(true);
    if (inputRef.current) {
      inputRef.current.focus(); // Foca no input diretamente
    }
  };

  return (
    <Card>
      <img src={image} alt={name} />
      <InputMask
        style={{borderBottom:err ? "1px solid red" :""}}
        inputRef={inputRef} // Usa a propriedade inputRef para referenciar o input DOM
        name={nameInput}
        value={value}
        placeholder={name}
        maskChar=""
        type={type}
        onFocus={onFocus}
        onBlur={onBlur}
        onChange={onChange}
        mask={mask}
      />
      {err 
      
      ?
      <p style={{color:"red"}}>{messageErr}</p>
      :
      <></>

      }
    </Card>
  );
};

export default InputShipping;
