import React, { useState, useContext, useEffect } from 'react'
import Cards from 'react-credit-cards-2';
import styled from 'styled-components'
import cardIcon from '../../images/svg/creditCardIcon.svg'
import PixIcon from '../../images/svg/icons8-foto.svg'
import 'react-credit-cards-2/dist/es/styles-compiled.css';
import animation from '../../images/animation/creditCard.json'
import InputShipping from "../InputShipping";
import creditCardIcon from '../../images/svg/creditCardInput.svg'
import { CartContext } from '../../context/cart';
import api from '../../services/api';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
const Card = styled.div`
  transition: all 0.3s;
  display: flex;
  flex-direction: column;
  width: 99%;
  height: auto;
  background-color: #fbfbfb;
  border: 1px solid #e0dcdc;

  @media screen and (max-width:820px){
    margin-top:12rem;
    padding:0.5rem;
    width: 99%;
  }

`;
const CardHeader = styled.div`
    display:flex;
    height:40px;
    padding-top: 0.9rem;
    padding-left: 0.5rem;
    justify-content: space-between;
    
    a{
        text-decoration: solid !important;
        cursor: pointer;
    }
    
`
const Row = styled.div`
    display: flex;


    p{
    font-family: "Lato", serif;
    font-weight: 700;
    font-style: normal;
  }

`
const CardIndex = styled.div` 
  transition: all .2s;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 100px;
  width: 25px;
  height: 25px;
  padding-top: 0.9rem;
  background-color: ${(props) => (props.active ? "#008d24" : "white")};

  border:${(props) => (props.active ? "none" : "2px solid #cecccc;")};

  p {
    font-size:${(props) => (props.active ? "0.9rem" : "0.8rem")};
    font-family: "Lato", serif;
    font-weight: 700;
    font-style: normal;
    color:${(props) => (props.active ? "white" : "#cecccc")} ;
  }
`;
const CardBody = styled.div`

   padding-left: 0.9rem;

   .textPrimary{
    font-size: 0.8rem;
    font-family: "Lato", serif;
    font-weight: 500;
    font-style: normal;
   }



`
const CardPayment = styled.div`
`
const CardPaymentHeader = styled.div`
`
const Option = styled.div`
    cursor:pointer;
    border: 1px solid #dddddd;
    padding-left:0.3rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 53px;
    background-color: white;
    box-shadow: 0 2px 0 #dadada;
    
    p{
        font-size: 0.8rem;
        font-family: "Lato", serif;
        font-weight: 700;
        color:#6e6e6e;
        position: relative;
        top: 9px;
        left: 9px;
    }

    img{
        width: 21px;
        height: 21px;
        }

        .textInstallment{
            font-size: 0.8rem;
        }

`
const CardButtons = styled.div`

    margin-top: 2rem;
    padding-left: 9rem;
    padding-right: 9rem;
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: center;
    width: 100%;

    button{
        width: 262.5px;
    }

@media screen and (max-width:820px){
        display:block;
        padding-left: 0.9rem;
        padding-right: 0.9rem;

        button{
            margin-top: 0.9rem;
            width: 100%;
        }

    }
`
const ButtonAdd = styled.button`
    border-radius: 2px;
    width: 100%;
    height: 49px;
    color:#414040;
    background-color: #FFFFFF;
    border: 1px solid #827c7c;

`
const ButtonContinue = styled.button`
    
    border-radius: 2px;
    font-family: "Lato", serif;
    font-weight: 500;
    color:#FFFFFF;
    width: 100%;
    height: 43px;
    background-color: #00BF00;
    border: none;
    

`
const ContainerCardCreditCard = styled.div`

    form > div{

        margin-bottom: 1.9rem;
        
    }


`
const ContainerImage = styled.div`
`
const Animation = styled.div`
    width:  220px;
    height: 220px;
`
const CardPix = styled.div`
`

const QrCode = styled.img`
    width: 150px;
    height: 150px;
    object-fit: contain;

`



const CardConfirmPaymentOptionMobile = ({ name, index, id }) => {

    const navigate = useNavigate()

    const [selectOption, setSelectOption] = useState()
    const [qrCode,setQrCode] = useState()
    const [state, setState] = useState({
        number: '',
        expiry: '',
        cvc: '',
        name: '',
        focus: '',
    });

    const handleOptionPrimary = () => {
        setSelectOption(1)

    }
    const handleInputChange = (evt) => {
        const { name, value } = evt.target;
        console.log(name)
        console.log(value)
        setState((prev) => ({ ...prev, [name]: value }));
    }
    const handleInputFocus = (evt) => {
        setState((prev) => ({ ...prev, focus: evt.target.name }));
    }
    const getQrCode = async (street,number,city,state,complement,zipCode) => {

        const response = await api.post("/payment/storage/pix", {
            price: "price",
            zipCode:zipCode,
            street: street,
            number:number,
            complement:complement,
            state:state,
            city:city,
            cart: JSON.parse(localStorage.getItem("@LOCAL_CART")),
            mail: localStorage.getItem('@USER_MAIL'),
        })

        if(response.status == 401){
            setTimeout(() => {
                navigate("/")
            },[1000])
            return false
        }

        if (response.status == 200) {
            setQrCode(response.data)
        }

    }

    useEffect(() => {
       
        getQrCode()
        
    },[])


    return (

        <Card className='card_shipping'   >
            <CardHeader>
                <Row>
                    <CardIndex >
                        <p>{index}</p>

                    </CardIndex>
                    <p>Cartão de credito</p>
                </Row>
            </CardHeader>

            <CardBody>


                {state

                    ?
                    <ContainerImage>
                        <Cards

                            number={state.number}
                            expiry={state.expiry}
                            cvc={state.cvc}
                            name={state.name}
                            focused={state.focus}
                            formData=''
                            issuer=''

                        />
                    </ContainerImage>
                    :
                    <></>

                }

                {selectOption == 1

                    ?
                    <></>
                    :
                    <p>asdasdsad</p>
                }

                {selectOption == undefined

                    ?
                    <>
                        <p className='textPrimary'>Selecione uma forma de pagamento para finalizar seu <br /> pedido:</p>
                        <CardPayment>
                            <CardPaymentHeader>
                                <Option onClick={() => setSelectOption(1)}>
                                    <img src={cardIcon} />
                                    <p>Cartão de Crédito <br />Parcele em até 12x</p>

                                    <p className='textInstallment'>até 12x</p>
                                </Option>
                                <Option onClick={() => setSelectOption(2)}>
                                    <img src={PixIcon} />
                                    <p>Pix - Mercado Livre<br />Pague á vista</p>
                                    <p className='textInstallment'>à vista</p>
                                </Option>
                            </CardPaymentHeader>
                        </CardPayment>
                    </>
                    :
                    selectOption == 1

                        ?
                        <Option onClick={() => setSelectOption(2)}>
                            <img src={PixIcon} />
                            <p>Pix - Mercado Livre<br />Pague á vista</p>
                            <p className='textInstallment'>à vista</p>
                        </Option>
                        :
                        <></>
                }

            </CardBody>
        </Card>
    )
}

export default CardConfirmPaymentOptionMobile