import React, { useEffect, useState } from "react";
import styled from "styled-components";
import ReactStars from 'react-stars'
import { useNavigate } from "react-router-dom";

const CardWrapper = styled.div`
    cursor: pointer;
    position: relative;
    border-radius: 6px;
    margin-top: 1rem;
    margin-bottom: 0.8rem;
    width: 265px;
    height: 430px;
    box-shadow: 0 0 5px #9f9f9f;
    margin-left: 40px;

    @media screen and (max-width:820px){
        margin-top: 2.5rem;
        height: 430px;
        padding-bottom: 0.9rem;
    }


`
const FreeShipping = styled.div`
    width: 100%;

    display: flex;
    justify-content: center;
    height: 30px;
    background-color: #1d992e;
    color: white;
    border-end-end-radius: 2px;
    border-end-start-radius:2px;

    p {    
    font-family: "Poppins", serif;
    font-weight: 600;
    font-style: normal;
    font-size: 0.8rem;
    height: 0px;
    margin-top: 0.3rem;

    }

    svg{
        margin-top: 0.6rem;
        margin-right: 0.4rem;
    }

`
const CardHeader = styled.div`
    position: relative;
    display: flex;
    height: 25px;
`
const ContainerCardBody = styled.div`
    margin-top: 0.5rem;
    padding-left: 0.9rem;
`
const CardBody = styled.div`
    
    .textPrimary{
        width: 220px;
        font-family: "Poppins", serif;
        font-weight: 600;
        font-style: normal;
        font-size: 0.8rem;
        text-align: left;
    }

    .textSecondary{
        color:#929292dd;
        width: 220px;
        font-family: "Poppins", serif;
        font-weight: 600;
        font-style: normal;
        font-size: 0.7rem;
        text-align: left;
        height: 1px;
    }

    .textPrice{
        color:#fe5000;
        width: 220px;
        font-family: "Poppins", serif;
        font-weight: 700;
        font-style: normal;
        font-size: 1.2rem;
        text-align: left;
        height: 15px;
    }

    .textTypePayment{
        color:#1d992e;
        width: 220px;
        font-family: "Poppins", serif;
        font-weight: 600;
        font-style: normal;
        font-size: 0.7rem;
        text-align: left;
        height: 7px;
    }
    .textInstallmentPayment{
        color:#151515dd;
        width: 220px;
        font-family: "Poppins", serif;
        font-weight: 600;
        font-style: normal;
        font-size: 0.7rem;
        text-align: left;
        height: 0px;
    }

`
const ContainerImage = styled.div`
    margin-top: 1.5rem;
    display: flex;
    justify-content: center;

`
const Image = styled.img`
    width: 139px;
    height: 139px;

`
const Button = styled.button`
    position: absolute;
    bottom: 15px;
    font-family: "Poppins", serif;
    font-weight: 500;
    font-style: normal;
    background-color: black;
    color: white;
    border: 1px solid black;
    margin-top: 1.9rem;
    width: 40%;
    height: 27px;
    font-size: 0.8rem;

`

const MarkImage = styled.img`
    position: absolute;
    right: 15px;
    width: 55px;
    height: 55px;

`

const CardDescount = styled.div`

    position: absolute;
`

const NewCard = ({ name, product_image, price, promotion_price, onClick, product_id, free_shipping, category_image }) => {

    const [installMent, setInstallMent] = useState()
    const [promotionalPrice, setPromotionalPrice] = useState()
    const [descount, setDescount] = useState()


    const navigate = useNavigate()

    useEffect(() => {
        const formmatedPrice = price.replace(",", ".")
        const formmatedPromotional = promotion_price.replace(",", ".")
        setPromotionalPrice(formmatedPromotional)
        setInstallMent(Number(formmatedPromotional) / 2)


    }, [promotion_price])


    const getPromotionalPrice = (price, promotion_price) => {
        const originalPrice = parseFloat(price.toString().replace(",", "."));
        const promotionalPrice = parseFloat(promotion_price.toString().replace(",", "."));

        if (originalPrice > 0 && promotionalPrice > 0) {
            // Calcula o desconto percentual
            const discountPercentage = ((originalPrice - promotionalPrice) / originalPrice) * 100;

            // Arredonda para baixo para pegar o valor inteiro
            const roundedDiscount = Math.floor(discountPercentage); // Use Math.round se quiser arredondar para o número inteiro mais próximo

            console.log(`Desconto: ${roundedDiscount}%`);
            setDescount(roundedDiscount);
        } else {
            console.log("Preço inválido ou promoção inexistente.");
            return 0;
        }
    };


    useEffect(() => {
        getPromotionalPrice(price, promotion_price)
    }, [price, promotion_price])


    return (

        <CardWrapper className="card_new_data">
            <CardHeader onClick={() => navigate(`/product/?id=${product_id}`)}>
                {free_shipping == "Sim"

                    ?
                    <FreeShipping>
                        <svg width="16" height="11" viewBox="0 0 16 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M1.83333 6.33334L1.33333 5.33334H4.83333L4.43333 4.33334H1.16667L0.666666 3.33334H5.86667L5.46667 2.33334H0.573333L0 1.33334H2.5C2.5 0.97971 2.64048 0.64057 2.89053 0.39053C3.14057 0.14048 3.47971 0 3.83333 0H11.8333V2.66667H13.8333L15.8333 5.33334V8.66671H14.5C14.5 9.19711 14.2893 9.70581 13.9142 10.0809C13.5392 10.4559 13.0304 10.6667 12.5 10.6667C11.9696 10.6667 11.4609 10.4559 11.0858 10.0809C10.7107 9.70581 10.5 9.19711 10.5 8.66671H7.83333C7.83333 9.19711 7.62262 9.70581 7.24755 10.0809C6.87248 10.4559 6.36377 10.6667 5.83333 10.6667C5.3029 10.6667 4.79419 10.4559 4.41912 10.0809C4.04405 9.70581 3.83333 9.19711 3.83333 8.66671H2.5V6.33334H1.83333ZM12.5 9.66671C12.7652 9.66671 13.0196 9.56131 13.2071 9.37381C13.3947 9.18621 13.5 8.93191 13.5 8.66671C13.5 8.40141 13.3947 8.14711 13.2071 7.95961C13.0196 7.77201 12.7652 7.66671 12.5 7.66671C12.2348 7.66671 11.9804 7.77201 11.7929 7.95961C11.6054 8.14711 11.5 8.40141 11.5 8.66671C11.5 8.93191 11.6054 9.18621 11.7929 9.37381C11.9804 9.56131 12.2348 9.66671 12.5 9.66671ZM13.5 3.66667H11.8333V5.33334H14.8067L13.5 3.66667ZM5.83333 9.66671C6.09855 9.66671 6.35291 9.56131 6.54044 9.37381C6.72798 9.18621 6.83333 8.93191 6.83333 8.66671C6.83333 8.40141 6.72798 8.14711 6.54044 7.95961C6.35291 7.77201 6.09855 7.66671 5.83333 7.66671C5.56812 7.66671 5.31376 7.77201 5.12623 7.95961C4.93869 8.14711 4.83333 8.40141 4.83333 8.66671C4.83333 8.93191 4.93869 9.18621 5.12623 9.37381C5.31376 9.56131 5.56812 9.66671 5.83333 9.66671Z" fill="white" />
                        </svg>

                        <p>FRETE GRÁTIS Consulte seu CEP*</p>
                    </FreeShipping>
                    :
                    <>
                        {!descount

                            ?
                            <></>
                            :
                            <div className='descount_card'>
                                <svg width="20" height="20" viewBox="0 0 31 44" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M15.6128 0.416664L12.5874 6.6C10.4255 11.0174 7.52811 15.035 4.01905 18.4808L3.62155 18.8562C1.36787 21.0442 0.080185 24.0408 0.0440533 27.1817V27.5792C-0.0156377 33.7296 3.64939 39.3057 9.31905 41.6904L9.89322 41.9333C13.6118 43.5128 17.8126 43.5128 21.5311 41.9333H21.6636C27.3757 39.4516 31.0406 33.7845 30.9607 27.5571V17.9729C29.0572 22.3201 25.6002 25.8007 21.2661 27.7337C21.2661 27.7337 21.2661 27.7337 21.1336 27.7337C21.0011 27.7337 19.4553 28.3742 18.7928 27.7337C18.2011 27.135 18.1444 26.1906 18.6603 25.5254L18.8149 25.415H18.9253C23.9951 21.5614 25.1771 14.4213 21.6195 9.13958C18.7486 4.76708 15.6128 0.416664 15.6128 0.416664Z" fill="white" />
                                </svg>
                                <p>{String(descount).replace("-", "")}% OFF</p>

                            </div>

                        }
                        <MarkImage src={category_image} />

                    </>
                }

            </CardHeader>
            <CardBody>
                <ContainerImage onClick={() => navigate(`/product/?id=${product_id}`)}>
                    <Image src={product_image} />
                </ContainerImage>
                <ContainerCardBody>
                    <p className="textPrimary" onClick={() => navigate(`/product/?id=${product_id}`)}>{name}</p>
                    <p className="textSecondary" onClick={() => navigate(`/product/?id=${product_id}`)}><s>DE: R$ {price}</s></p>
                    <p className="textPrice" onClick={() => navigate(`/product/?id=${product_id}`)}>R$ {Number(promotionalPrice).toLocaleString("pt-BR", { minimumFractionDigits: 2 })}</p>
                    <p className="textTypePayment" onClick={() => navigate(`/product/?id=${product_id}`)}>à vista no Pix ou Boleto</p>
                    <p className="textInstallmentPayment" onClick={() => navigate(`/product/?id=${product_id}`)}>ou R$ {promotion_price.replace(".", ",")} em 12x de R$ {Number(installMent).toLocaleString("pt-BR", { minimumFractionDigits: 2 })} sem juros no cartão</p>

                    <Button className="btn_new_card" onClick={onClick}>Comprar </Button>
                </ContainerCardBody>
            </CardBody>

        </CardWrapper>


    )
}

export default NewCard