import React, { createContext, useState, useEffect } from 'react';
import axios from 'axios';
import api from '../services/api';

export const CartContext = createContext({});

function CartProvider({ children }) {
    const [cart, setCart] = useState([]);
    const [price, setPrice] = useState(0);
    const [priceAddress, setPriceAddress] = useState(0);
    const [dateFreight, setDateFreight] = useState('');
    const [priceFreight, setPriceFreight] = useState(0);
    const [cartLength, setCartLength] = useState(0);
    const [stateCart, setStateCart] = useState(false);
    const [userSelectState, setUserSelect] = useState()
    const [cep, setCep] = useState()
    const [userSelectCity, setUserSelectCity] = useState()
    const [street, setStreet] = useState()
    const [number, setNumber] = useState()
    const [coustFlex, setCoustFlex] = useState()
    const [totalCoust, setTotalCoust] = useState(0)
    const [coustJadlog, setCoustJadlog] = useState()
    const [complement, setComplement] = useState()
    const [shippmentCartValue, setShippementCartValue] = useState()
    const [selectInfo, setSelectInfo] = useState(2)
    const [animation, setanimation] = useState(false)
    const [optionDelivery, setOptionDelivery] = useState()

    const handleAddToCart = async (data) => {


        // const itemCart = cart.find(item => item.name === data.name);
        // if (itemCart) {
        //     setStateCart(true);

        //     return false;
        // }

        localStorage.setItem("@LOCAL_CART", JSON.stringify([...cart, { ...data, quantity: 1 }]))
        setCart([...cart, { ...data, quantity: 1 }]);

        setStateCart(true);
    };
    const recoveryCart = () => {


        if (localStorage.getItem("@LOCAL_CART")) {

            const data = JSON.parse(localStorage.getItem("@LOCAL_CART"))
            if (data) {
                setCart(data)
            }
        }



    }
    const handleShippmentCartValue = (e) => {
        setShippementCartValue(e)
    }
    const handleStateCart = (state) => {
        if (cart.length == 0) {
            setStateCart(false);
            return false
        }
        setStateCart(state);
    };
    const handleRemoveItem = (index) => {

        if (cart.length == 0) {
            setStateCart(false)
        }

        const local_data_copy = JSON.parse(localStorage.getItem("@LOCAL_CART"))
        const copy = [...cart];
        copy.splice(index, 1);
        setCart(copy);
        local_data_copy.splice(index, 1)
        localStorage.setItem("@LOCAL_CART", JSON.stringify(local_data_copy))



    };
    const handleUpdateQuantity = (index, quantity) => {
        if (quantity) {
            const copyLocalCart = JSON.parse(localStorage.getItem("@LOCAL_CART"))

            copyLocalCart[index].quantity = quantity
            localStorage.setItem("@LOCAL_CART", JSON.stringify(copyLocalCart))
            const copy = [...cart];
            copy[index].quantity = quantity;
            setCart(copy);
        }
    };
    const getCep = async (cep) => {
        const response = await axios.get(`https://viacep.com.br/ws/${cep}/json/`)
        if (response.status == 200) {
            setStreet(response.data.logradouro)
            setUserSelect(response.data.uf)
            setUserSelectCity(response.data.localidade)
        }

    }
    const handleFreight = async (cep) => {
        if (cep) {
            setanimation(true)
            const response = await api.get(`client/get/type/delivery/product/${cep}`)

            if (response.data.type_delivery == "1") {
                setOptionDelivery(1)
                // Filtra os itens que não possuem frete grátis
                const itemsWithoutFreeShipping = cart.filter(item => item.free_shipping == "Não");

                if (itemsWithoutFreeShipping.length === 0) {
                    // Todos os itens têm frete grátis
                    setDateFreight(0);
                    setPriceAddress(0); // Define o valor do frete como 0
                    getCep(String(cep).replace("-", ""));
                    setCep(cep);
                }

                const reduceWeight = itemsWithoutFreeShipping.reduce(
                    (acc, item) => acc + Number(item.quantity),
                    0
                );
                // Agora, somando o custo do frete uma única vez, após calcular o peso total
                const totalWeight = Number(reduceWeight) * Number(response.data.coust_delivery);

                // Atualizando o estado com o valor correto
                setPriceAddress(totalWeight)
                setanimation(false)

                return false

            } else {
                setOptionDelivery(2)
                // Filtra os itens que não possuem frete grátis
                const itemsWithoutFreeShipping = cart.filter(item => item.free_shipping == "Não");

                if (itemsWithoutFreeShipping.length === 0) {
                    // Todos os itens têm frete grátis
                    setDateFreight(0);
                    setPriceAddress(0); // Define o valor do frete como 0
                    getCep(String(cep).replace("-", ""));
                    setCep(cep);
                    setanimation(false)

                } else {
                    // Calcula o peso total apenas dos itens sem frete grátis
                    const reduceWeight = itemsWithoutFreeShipping.reduce(
                        (acc, item) => acc + Number(item.weight) * Number(item.quantity),
                        0
                    );

                    const responseApi = await api.post("client/get/freight/cart", {
                        cep: String(cep).replace("-", ""),
                        weight: reduceWeight
                    });


                    if (responseApi.status === 200) {
                        setDateFreight(responseApi.data.prazo);

                        console.log(responseApi)
                        setPriceAddress(responseApi.data.vlTotal);
                        setTotalCoust(Number(reduceWeight) + responseApi.data.vlTotal)
                        getCep(String(cep).replace("-", ""));
                        setCep(cep);
                        setanimation(false)

                    }
                }
            }


        }
    };

    const handleFreightPayment = async (cep) => {
        if (cep) {
            setanimation(true)
            const response = await api.get(`client/get/type/delivery/product/${cep}`)

            if (response.data.type_delivery == "1") {
                setOptionDelivery(1)
                // Filtra os itens que não possuem frete grátis
                const itemsWithoutFreeShipping = cart.filter(item => item.free_shipping == "Não");

                if (itemsWithoutFreeShipping.length === 0) {
                    // Todos os itens têm frete grátis
                    setDateFreight(0);
                    setPriceAddress(0); // Define o valor do frete como 0
                    getCep(String(cep).replace("-", ""));
                }

                const reduceWeight = itemsWithoutFreeShipping.reduce(
                    (acc, item) => acc + Number(item.quantity),
                    0
                );
                // Agora, somando o custo do frete uma única vez, após calcular o peso total
                const totalWeight = Number(reduceWeight) * Number(response.data.coust_delivery);

                // Atualizando o estado com o valor correto
                setPriceAddress(totalWeight)
                setanimation(false)

                return false

            } else {
                setOptionDelivery(2)
                // Filtra os itens que não possuem frete grátis
                const itemsWithoutFreeShipping = cart.filter(item => item.free_shipping == "Não");

                if (itemsWithoutFreeShipping.length === 0) {
                    // Todos os itens têm frete grátis
                    setDateFreight(0);
                    setPriceAddress(0); // Define o valor do frete como 0
                    getCep(String(cep).replace("-", ""));
                    setanimation(false)

                } else {
                    // Calcula o peso total apenas dos itens sem frete grátis
                    const reduceWeight = itemsWithoutFreeShipping.reduce(
                        (acc, item) => acc + Number(item.weight) * Number(item.quantity),
                        0
                    );

                    const responseApi = await api.post("client/get/freight/cart", {
                        cep: String(cep).replace("-", ""),
                        weight: reduceWeight
                    });


                    if (responseApi.status === 200) {
                        setDateFreight(responseApi.data.prazo);

                        console.log(responseApi)
                        setPriceAddress(responseApi.data.vlTotal);
                        setTotalCoust(Number(reduceWeight) + responseApi.data.vlTotal)
                        getCep(String(cep).replace("-", ""));
                        setanimation(false)

                    }
                }
            }


        }
    };


    useEffect(() => {
        // Calcula o preço total sempre que o carrinho é atualizado
        const total = cart.reduce((acc, item) => {
            const itemPrice = item.promotion_price && item.promotion_price !== "0"
                ? Number(item.promotion_price)
                : Number(item.price);
            return acc + itemPrice * item.quantity;
        }, 0);

        if (shippmentCartValue) {
            setPrice(total + Number(shippmentCartValue));
        } else {
            setPrice(total)
        }
        setCartLength(cart.length);

        // Atualiza o cálculo do frete baseado no preço do endereço
        const totalQuantity = cart.reduce((a, item) => a + Number(item.quantity), 0);
        handleFreight(cep);
    }, [cart, priceAddress, cep, shippmentCartValue]);

    useEffect(() => {

        recoveryCart()
    }, [])



    return (
        <CartContext.Provider value={{
            handleAddToCart,
            handleStateCart,
            handleRemoveItem,
            handleUpdateQuantity,
            handleFreight, 
            cart, 
            price, 
            cartLength, 
            stateCart, 
            priceFreight, 
            street,
            userSelectState,
            userSelectCity,
            setUserSelect,
            setUserSelectCity,
            setNumber,
            setStreet,
            number,
            priceAddress,
            setCep,
            cep,
            setComplement,
            complement,
            totalCoust,
            handleShippmentCartValue,
            shippmentCartValue,
            setPrice,
            setSelectInfo,
            selectInfo,
            dateFreight,
            animation,
            setanimation,
            optionDelivery,
            handleFreightPayment
        }}>
            {children}
        </CartContext.Provider>
    );
}

export default CartProvider;
