import React, { useContext, useState, useEffect } from 'react'
import axios from 'axios'
import { CartContext } from '../../context/cart'
import { useNavigate } from 'react-router-dom'
import api from '../../services/api'
import InputAccount from '../InputAccount'
import io from 'socket.io-client';
import ReactLoading from 'react-loading';

import cardsIcon from '../../images/svg/cardsIcon.svg'
import { toast, ToastContainer } from 'react-toastify'
import TableListDesktop from '../TableListDesktop'
import Animation from '../Animation'
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Address from '../Address'
import TableListModal from '../TableListModal'
import { CopyToClipboard } from 'react-copy-to-clipboard';
import styled from 'styled-components'

const CardWrapper = styled.div`
    background-color: rgba(255, 255, 255, 0.18);
    width: 320px;
    display: flex;
    height: 90px;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    border-radius:15px;
    padding: 0.2rem;
    box-shadow: 0 0 5px rgb(103, 103, 103);
    margin-bottom: 0.9rem;
    .primary{
        font-family: "OpenSans-Bold";
        color:rgb(49, 49, 49);
        font-size: 1.5rem;
        height: 0.3rem;
        margin-bottom: 2.3rem;
    }

    .secondary{
        font-family: "OpenSans-Medium";
        color:rgb(49, 49, 49);
        font-size: 1.3rem;
        height: 0.3rem;
        margin-bottom: 2.3rem;
    }
    .shippment_text{
        font-family: "OpenSans-Medium";
        color:rgb(49, 49, 49);
        font-size: 1.2rem;
        height: 0rem;
        margin-bottom: 2.8rem;
    }

    p{
        text-align: center;
        height: 16px;
    }

    @media screen and (max-width:720px){
        width: 90%;

        p{
            text-align: center;
            height: 11px;

        }
    }


`



const ContainerPaymentProduct = () => {
    const { cart } = useContext(CartContext)

    const socket = io("https://api-tkg.onrender.com/", {
        transports: ['websocket'], // Garante que o WebSocket seja usado
        reconnectionAttempts: 5,  // Tenta reconectar até 5 vezes
    });
    const navigate = useNavigate()

    const [addresses, setAdresses] = useState([])


    const [checkAdress, setCheckAddress] = useState()

    const [option, setOption] = useState(0)
    const [animation, setAnimation] = useState(false)
    const [nameCard, setNameCard] = useState()
    const [numberCard, setNumberCard] = useState()
    const [expiration, setExpiration] = useState()
    const [totalPay, setTotalPay] = useState()

    const [cpf, setCpf] = useState()

    const [securityCode, setSecurityCode] = useState()
    const [selectInstallment, setSelectInstallment] = useState()
    const [installments, setInstallments] = useState([])
    const [animationPay, setAnimationPay] = useState()
    const { price, handleFreight, priceAddress, setCep, coustFlex, coustJadlog, totalCoust } = useContext(CartContext)

    const [brazilianStates, setBrazilianStates] = useState([])
    const [brazilianCitys, setBrazilianCitys] = useState([])


    const [userSelectState, setUserSelectState] = useState()

    const [qrCode, setQrCode] = useState()
    const [modal, setModal] = useState(true)
    const [selectAdresses, setSelectAdress] = useState([])
    const [btnAdress, setBtnAdress] = useState(false)

    const handleBtnPayment = async () => {

        if (option == 1) {
            if (!nameCard) {
                return toast.error("Informe um nome do titular")
            }
            if (!numberCard) {
                return toast.error("Informe um número do cartão")
            }
            if (!expiration) {
                return toast.error("Informe uma validade")
            }
            if (!securityCode) {
                return toast.error("Informe um cvv")
            }
            if (!cpf) {
                return toast.error("Informe um CPF")
            }

            setAnimation(true)

            const formatedExpiration = String(expiration).split("")

            const response = await api.post("payment/storage/",
                {

                    "city": selectAdresses[0].city,
                    "cpfNumber": cpf,
                    "street": selectAdresses[0].street,
                    "number": selectAdresses[0].number,
                    "complement": selectAdresses[0].complement,
                    "state": selectAdresses[0].state,
                    "zipCode": selectAdresses[0].zipCode,
                    "payment_id": "1",
                    "mail": localStorage.getItem('@USER_MAIL'),
                    "cart": JSON.parse(localStorage.getItem("@LOCAL_CART")),
                    "cpf": cpf,
                    "payment_option": option,
                    "card_number": String(numberCard).replace(" ", "").replace(" ", "").replace(" ", "").replace(" ", ""),
                    "expiration_month": `${formatedExpiration[0]}${formatedExpiration[1]}`,
                    "expiration_year": `${formatedExpiration[3]}${formatedExpiration[4]}${formatedExpiration[5]}${formatedExpiration[6]}`,
                    "security_code": securityCode,
                    "cardholder": nameCard,
                    "price": price,
                    "installments": selectInstallment,
                    "status": 1,
                }

            )

            if (response.status == 200) {
                setAnimation(false)
                localStorage.removeItem("@LOCAL_CART")
                window.location.href = "/dashboard/v2"
            } else {
                setAnimation(false)
                toast.error(response.data.message, {
                    autoClose: 1500
                })
            }
        }

    }
    const getQrCode = async (street, number, city, state, complement, zipCode) => {

        const response = await api.post("/payment/storage/pix", {
            price: Number(price),
            zipCode: zipCode,
            street: street,
            number: number,
            complement: complement,
            state: state,
            city: city,
            cart: JSON.parse(localStorage.getItem("@LOCAL_CART")),
            mail: localStorage.getItem('@USER_MAIL'),
        })

        if (response.status == 401) {
            return false
        }

        if (response.status == 200) {
            setQrCode(response.data)
        }

    }
    const getAddress = async () => {
        const response = await api.get("addresses/get")

        if (response.status == 404) {
            setBtnAdress(true)
            setAdresses([])
            return false
        }

        if (response.status == 200) {
            setAdresses(response.data)
            setBtnAdress(false)
        }
    }
    const copyPix = async () => {
        toast.success("Chave pix copiada com sucesso!", {
            autoClose: 1500
        })

    };
    const redirectToDashboardPage = () => {
        navigate("/dashboard/v2/address")
    }

    const animationPayModal = () => {

        const total = price + totalCoust
        setTotalPay(Number(total).toLocaleString("pt-br", { minimumFractionDigits: 2 }))

        setAnimationPay(true)
        setTimeout(() => {
            setAnimationPay(false)

        }, [2100])

    }


    useEffect(() => {
        const getBrazilianStates = async () => {
            const response = await axios.get(
                "https://servicodados.ibge.gov.br/api/v1/localidades/estados"
            );

            setBrazilianStates(response.data);
        };

        getBrazilianStates();
    }, []);


    useEffect(() => {
        const getCitys = async () => {
            const response = await axios.get(
                `https://servicodados.ibge.gov.br/api/v1/localidades/estados/${userSelectState}/distritos`
            );


            setBrazilianCitys(response.data)
        }
        getCitys()

    }, [userSelectState])
    useEffect(() => {

        const array = []

        for (let index = 0; index <= 12; index++) {
            if (index == 0) {
                continue;
            }
            array.push({ nome: index })
        }

        setInstallments(array)

    }, [])
    useEffect(() => {
        getAddress()
    }, [])
    useEffect(() => {
        // Ouvir o evento 'new-order' que vem do servidor
        socket.on('new-order', (data) => {
            console.log(data); // Aqui você pode processar a mensagem recebida
            // Lógica para redirecionar o usuário para outra página
            window.location.href = '/dashboard/v2';  // Redireciona para a página de confirmação
        });

        // Limpar o socket quando o componente for desmontado
        return () => {
            socket.off('new-order');  // Remove o ouvinte de eventos
        };
    }, []);

    useEffect(() => {
        if (selectAdresses.length > 0) {
            handleFreight(selectAdresses[0].zipCode)
            setCep(selectAdresses[0].zipCode)
        }
    }, [selectAdresses])



    return (

        <div className='container_payment'>
            {animation == true
                ?

                <div className='container_animation_pages'>
                    <div>
                        <Animation />
                    </div>
                </div>
                :
                <></>
            }


            <ToastContainer />


            <div className='box_container_payment'>
            </div>

            <Modal

                show={modal}
                onHide={() => navigate("/payment")}
                size="lg"

                aria-labelledby="example-modal-sizes-title-lg"
                centered
            >
                <Modal.Header >

                    <div className='row_box_payment_modal'>

                        {option == 0

                            ?
                            <div onClick={() => setOption(0)} className={option == 0 ? 'box_payment_modal_checked' : 'box_payment_modal'}>

                                <svg width="43" height="47" viewBox="0 0 46 47" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M29.1691 15.7434C28.9941 15.7228 28.8174 15.7228 28.6425 15.7434L19.1231 18.2075C18.3653 18.3954 17.7736 18.9871 17.5857 19.7449L15.1848 29.3486C15.0265 29.9878 15.1763 30.6644 15.5897 31.177C16.0031 31.6897 16.6326 31.9794 17.2909 31.9601C17.4658 31.9807 17.6425 31.9807 17.8174 31.9601L27.4421 29.496C28.1999 29.3081 28.7916 28.7164 28.9795 27.9586L31.3804 18.3549C31.5388 17.7157 31.3889 17.0391 30.9755 16.5265C30.5621 16.0138 29.9327 15.7241 29.2744 15.7434H29.1691ZM26.8524 20.1451L25.3781 26.0842L19.439 27.5584L20.9133 21.6193L26.8524 20.1451Z" fill="black" />
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M27.6948 2.53842H18.7651C9.45995 2.53842 1.91663 10.0817 1.91663 19.3869V28.3166C1.91663 37.6218 9.45995 45.1651 18.7651 45.1651H27.6948C37 45.1651 44.5433 37.6218 44.5433 28.3166V19.3869C44.5433 10.0817 37 2.53842 27.6948 2.53842ZM18.7651 42.006C11.2095 41.9944 5.08732 35.8722 5.07572 28.3166V19.3869C5.08732 11.8313 11.2095 5.70911 18.7651 5.69751H27.6948C35.2504 5.70911 41.3726 11.8313 41.3842 19.3869V28.3166C41.3726 35.8722 35.2504 41.9944 27.6948 42.006H18.7651Z" fill="black" />
                                </svg>

                                <p>Endereços</p>


                            </div>
                            :
                            <>
                                <div onClick={() => setOption(0)} className={option == 0 ? 'box_payment_modal_checked' : 'box_payment_modal'}>

                                    <svg width="43" height="47" viewBox="0 0 46 47" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M29.1691 15.7434C28.9941 15.7228 28.8174 15.7228 28.6425 15.7434L19.1231 18.2075C18.3653 18.3954 17.7736 18.9871 17.5857 19.7449L15.1848 29.3486C15.0265 29.9878 15.1763 30.6644 15.5897 31.177C16.0031 31.6897 16.6326 31.9794 17.2909 31.9601C17.4658 31.9807 17.6425 31.9807 17.8174 31.9601L27.4421 29.496C28.1999 29.3081 28.7916 28.7164 28.9795 27.9586L31.3804 18.3549C31.5388 17.7157 31.3889 17.0391 30.9755 16.5265C30.5621 16.0138 29.9327 15.7241 29.2744 15.7434H29.1691ZM26.8524 20.1451L25.3781 26.0842L19.439 27.5584L20.9133 21.6193L26.8524 20.1451Z" fill="black" />
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M27.6948 2.53842H18.7651C9.45995 2.53842 1.91663 10.0817 1.91663 19.3869V28.3166C1.91663 37.6218 9.45995 45.1651 18.7651 45.1651H27.6948C37 45.1651 44.5433 37.6218 44.5433 28.3166V19.3869C44.5433 10.0817 37 2.53842 27.6948 2.53842ZM18.7651 42.006C11.2095 41.9944 5.08732 35.8722 5.07572 28.3166V19.3869C5.08732 11.8313 11.2095 5.70911 18.7651 5.69751H27.6948C35.2504 5.70911 41.3726 11.8313 41.3842 19.3869V28.3166C41.3726 35.8722 35.2504 41.9944 27.6948 42.006H18.7651Z" fill="black" />
                                    </svg>

                                    <p>Endereços</p>


                                </div>
                                <div onClick={() => setOption(1)} className={option == 1 ? 'box_payment_modal_checked' : 'box_payment_modal'}>

                                    <svg width="60" height="38" viewBox="0 0 60 38" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <rect x="0.5" y="0.5" width="59" height="37" rx="7.5" fill="white" stroke="#D3D3D3" />
                                        <path d="M1 10L59 10" stroke="black" stroke-width="7" />
                                        <path d="M5 20H34" stroke="black" stroke-width="2" stroke-linecap="round" />
                                        <path d="M5 27H25" stroke="black" stroke-width="2" stroke-linecap="round" />
                                        <rect x="46" y="21" width="10" height="7" rx="2" fill="black" />
                                    </svg>
                                    <p>Cartão</p>


                                </div>
                                <div onClick={() => { setOption(2) }} className={option == 2 ? 'box_payment_modal_checked' : 'box_payment_modal'}>
                                    <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M28.0756 27.5378C27.3813 27.5403 26.6935 27.4052 26.0517 27.1403C25.41 26.8754 24.8271 26.486 24.3368 25.9946L18.9361 20.5905C18.7451 20.4079 18.491 20.3059 18.2267 20.3059C17.9624 20.3059 17.7083 20.4079 17.5173 20.5905L12.0984 26.0094C11.6083 26.5016 11.0256 26.8918 10.3839 27.1574C9.74215 27.4231 9.05413 27.5589 8.35959 27.5571H7.30228L14.1457 34.4006C15.1721 35.4248 16.5629 36 18.0128 36C19.4628 36 20.8536 35.4248 21.88 34.4006L28.7371 27.5423L28.0756 27.5378Z" fill="#32BCAD" />
                                        <path d="M8.36073 8.43998C9.05528 8.43812 9.74332 8.57395 10.3851 8.83962C11.0268 9.10528 11.6095 9.4955 12.0995 9.98774L17.5184 15.4077C17.7068 15.5955 17.9619 15.701 18.2278 15.701C18.4938 15.701 18.7489 15.5955 18.9373 15.4077L24.3368 10.0083C24.8264 9.51542 25.409 9.12471 26.0508 8.85883C26.6926 8.59294 27.3809 8.45716 28.0756 8.45937H28.7257L21.8686 1.60223C21.3608 1.09428 20.7579 0.69133 20.0944 0.416414C19.4309 0.141499 18.7197 0 18.0014 0C17.2832 0 16.572 0.141499 15.9085 0.416414C15.245 0.69133 14.6421 1.09428 14.1343 1.60223L7.30228 8.43998H8.36073Z" fill="#32BCAD" />
                                        <path d="M34.3978 14.1326L30.2541 9.98889C30.1606 10.0271 30.0607 10.0472 29.9598 10.0482H28.0756C27.0951 10.0507 26.1552 10.4401 25.4602 11.1317L20.0607 16.5278C19.5745 17.0132 18.9155 17.2858 18.2284 17.2858C17.5413 17.2858 16.8823 17.0132 16.3961 16.5278L10.9761 11.1112C10.2813 10.4191 9.34139 10.0293 8.36073 10.0265H6.04764C5.95232 10.0244 5.85811 10.0055 5.76934 9.97064L1.5994 14.1326C0.575205 15.159 0 16.5497 0 17.9997C0 19.4497 0.575205 20.8405 1.5994 21.8668L5.76022 26.0276C5.8488 25.9922 5.94312 25.9732 6.03852 25.9718H8.36073C9.34137 25.9689 10.2812 25.5791 10.9761 24.8871L16.3949 19.4682C17.3747 18.4896 19.0821 18.4896 20.0607 19.4682L25.4602 24.8665C26.1552 25.5582 27.0951 25.9476 28.0756 25.9501H29.9598C30.0608 25.9508 30.1607 25.9709 30.2541 26.0094L34.3978 21.8657C34.9057 21.3579 35.3087 20.7551 35.5836 20.0915C35.8585 19.428 36 18.7168 36 17.9986C36 17.2803 35.8585 16.5692 35.5836 15.9056C35.3087 15.2421 34.9057 14.6392 34.3978 14.1315" fill="#32BCAD" />
                                    </svg>

                                    <p>Pix</p>
                                </div>
                            </>
                        }


                    </div>

                </Modal.Header>
                <Modal.Body>

                    <div className='container_primary_payment'>
                        {animationPay == true

                            ?


                            <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                <ReactLoading type={"spin"} color='black' width={50} />
                            </div>
                            :
                            <></>
                        }


                        {option == 1 && animationPay == false

                            ?

                            <>

                                <p>Resumo do pedido </p>


                                <table class="table table-bordered">
                                    <thead>
                                        <tr>
                                            <th scope="col">Imagem</th>
                                            <th scope="col">Produto</th>
                                            <th scope="col">Preço</th>
                                            {/* <th scope="col">Frete</th> */}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {cart?.map(item => {
                                            return (

                                                <TableListModal cart={cart} priceAddress={priceAddress} name={item.name} product_weight={item.weight} price={Number(item.price).toLocaleString("pt-BR", { minimumFractionDigits: 2 })} image={item.product_image} id={item.id} promotion_price={item.promotion_price} />

                                            )
                                        })}
                                    </tbody>
                                </table>
                                <CardWrapper>
                                <p className='primary'>Total + Frete R$ {Number(price).toLocaleString("pt-br",{minimumFractionDigits:2})}</p>

                                </CardWrapper>


                                <p style={{height:"0px",textAlign:"left"}}>Cartão de crédito </p>

                                <img src={cardsIcon} />
                                <div className='row_input_address_payment'>
                                    <InputAccount required={true} name={"Nome Titular *"} id={5} mask={""} onChange={(e) => setNameCard(e.target.value)} />
                                    <InputAccount name="Número Cartão" mask={"9999 9999 9999 9999"} onChange={(e) => setNumberCard(e.target.value)} required={true} />
                                </div>

                                <div className='row_input_address_payment'>
                                    <InputAccount name="Validade" mask={"99/9999"} onChange={(e) => setExpiration(e.target.value)} required={true} />
                                    <InputAccount name="Cvv" mask={"999"} onChange={(e) => setSecurityCode(e.target.value)} required={true} />
                                </div>

                                <div className='row_input_address_payment'>
                                    <InputAccount name="CPF titular" mask={"999.999.999-99"} onChange={(e) => setCpf(e.target.value)} required={true} />
                                    <InputAccount required={true} onChange={(e) => setSelectInstallment(e.target.value)} value={"Selecione"} name={"N parcelas *"} option={installments} id={9} mask={""} type={"select"} />

                                </div>
                            </>
                            :
                            option == 2 && animationPay == false

                                ?


                                <div className='container_primary_payment_pix' >
                                    <p className='titleContainer_primary'>Resumo do pedido</p>

                                    <table class="table table-bordered">
                                    <thead>
                                        <tr>
                                            <th scope="col">Imagem</th>
                                            <th scope="col">Produto</th>
                                            <th scope="col">Preço</th>
                                            {/* <th scope="col">Frete</th> */}
                                            </tr>
                                    </thead>
                                    <tbody>
                                        {cart?.map(item => {
                                            console.log(item)
                                            return (

                                                <TableListModal cart={cart} priceAddress={priceAddress} name={item.name} product_weight={item.weight} price={Number(item.price).toLocaleString("pt-BR", { minimumFractionDigits: 2 })} image={item.product_image} id={item.id} promotion_price={item.promotion_price} />

                                            )
                                        })}
                                    </tbody>
                                </table>
                                <CardWrapper>
                                    <p className='primary'>Total + Frete R$ {Number(price).toLocaleString("pt-br",{minimumFractionDigits:2})}</p>

                                </CardWrapper>
                                    

                                    {!qrCode

                                        ?
                                        <ReactLoading type={"spin"} color='black' width={50} />
                                        :
                                        <>
                                            <img className='image_pix' src={`data:image/jpeg;base64,${qrCode?.qr_code_base64}`} />
                                            <input type='text' value={qrCode?.qr_code} />
                                            <CopyToClipboard text={qrCode?.qr_code} onCopy={copyPix}>
                                                <button type="button" style={{ marginTop: "1rem" }} class="btn btn-primary" >Copiar chave pix</button>
                                            </CopyToClipboard>
                                        </>

                                    }



                                </div>
                                :
                                <div className='container_primary_payment_pix' >
                                    {animationPay == true

                                        ?
                                        <></>
                                        :

                                        <>
                                            {btnAdress == false ? <p className='titleContainer_primary'>Endereço de entrega</p> : <></>}

                                            {btnAdress == false

                                                ?
                                                addresses.map(item => {
                                                    return (
                                                        <Address onClick={() => { setCheckAddress(item.id); animationPayModal(); setOption(1); setSelectAdress([{ street: item.street, number: item.number, city: item.city, state: item.state, complement: item.complement, zipCode: item.zipCode }]); getQrCode(item.street, item.number, item.city, item.state, item.complement, item.zipCode) }} street={item.street} number={item.number} city={item.city} id={item.id} checkId={checkAdress} />
                                                    )
                                                })

                                                :
                                                <button type="button" class="btn btn-dark" onClick={() => redirectToDashboardPage()}>Adicionar endereço</button>

                                            }
                                        </>

                                    }




                                </div>

                        }

                    </div>


                </Modal.Body>
                <Modal.Footer>

                    {option == 1

                        ?

                        <Button onClick={() => handleBtnPayment()}>Confirmar</Button>
                        :
                        <></>
                    }
                </Modal.Footer>
            </Modal>


        </div>

    )
}

export default ContainerPaymentProduct