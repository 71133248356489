import React, { useState, useRef, useEffect, useContext } from 'react';
import styled from 'styled-components';
import InputMask from 'react-input-mask';
import { CartContext } from '../../context/cart';
const Card = styled.div`
  width: 100%;
  position: relative;

  img {
    position: absolute;
    top: 5px;
    width: 16px;
    height: 16px;
  }

  select {
    width: 100%;
    padding-left: 1.5rem;
    background-color: transparent;
    border: none;
    border-bottom: 1px solid #dddddd;
    outline: none;
  }

  @media screen and (max-width:820px){
    label {
    position: absolute;
    left: 25px;
    transition: all 0.2s ease-in-out;
    color: #999;
    cursor: pointer;
  }
  }

  label {
    position: absolute;
    left: 25px;
    transition: all 0.2s ease-in-out;
    color: #999;
    cursor: pointer;
  }

  .toTop {
    top: -15px;
    left: 15px;
    font-size: 0.8rem;
    color: #333;
  }
`;

const SelectShipping = ({ name, image, onChange, value, nameInput, onFocus, mask, type, onBlur, err, messageErr, dataValue }) => {
    
    const {priceAddress} = useContext(CartContext)
    const [installments,setInstallments] = useState([])
    const inputRef = useRef(null)
    useEffect(() => {

        const data = []
        for (let index = 0; index <=12; index++) {
            
            if(index == 0){
                continue
            }

            const calcShipping = Number(priceAddress) + Number(dataValue)

            const calc = Number(calcShipping) / Number(index)


            data.push(`${index} x ${calc.toFixed(2).replace(".",",")} sem juros`)
        }

        setInstallments(data)

    },[])


    return (
        <Card>
            <img src={image} alt={name} />
            <select
                style={{ borderBottom: err ? "1px solid red" : "" }}
                inputRef={inputRef} // Usa a propriedade inputRef para referenciar o input DOM
                name={nameInput}
                value={value}
                placeholder={name}
                maskChar=""
                type={type}
                onFocus={onFocus}
                onBlur={onBlur}
                onChange={onChange}
                mask={mask}
            >

                {installments?.map((item,index) => {
                    const splitText = item.split(" ")
                    return(
                        <option value={splitText[0]}>{item}</option>
                    )
                })}

            </select>
            {err

                ?
                <p style={{ color: "red" }}>{messageErr}</p>
                :
                <></>

            }
        </Card>
    );
};

export default SelectShipping;
