import React from 'react'
import styled from 'styled-components'
import approvedIcon from '../../images/svg/approvedIcon.svg'

const CardWrapper = styled.div`
    display: flex;
    justify-content: center;
    flex-direction:column;
    align-items: center;

    .textPrimary_payment{
        font-family: "Poppins", serif;
        font-size: 1.2rem;
    }

    .textSecondary_payment{
        font-family: "Poppins", serif;
        font-size: 0.9rem;
        text-align: center;
    }

    @media screen and (max-width:820px){
        .textPrimary_payment{
            font-weight: medium;
            font-size: 1.1rem;
        }
    }


`
const ContainerButtons = styled.div`
    margin-top: 1rem;
    display: flex;
    flex-direction: column;
    
    button{
        font-family: "Poppins", serif;
        width: 320px;
        margin-bottom: 0.9rem;
    }
`
const Image = styled.img`
    width: 420px;
    height: 420px;

    @media screen and (max-width:820px){
        width: 90%;
        height: 350px;
    }

`
const handleRedirectToDashbord = () => {
    window.location.href="/dashboard/v2"
}

const SucessPayment = () => {
    return (

        <CardWrapper>
            <Image src={approvedIcon} />
            <p className='textPrimary_payment'>Pagamento Aprovado Com Sucesso!</p>
            <p className='textSecondary_payment'>Pagamento confirmado! Você receberá <br/> os detalhes em seu e-mail.  
            </p>
            <ContainerButtons>
                <button type="button" class="btn btn-dark" onClick={() => handleRedirectToDashbord() }>Acompanhar Meu Pedido</button>
                <button type="button" class="btn btn-outline-success"  onClick={() => window.location.href="/" }>Comprar Mais</button>
            </ContainerButtons>

        </CardWrapper>

    )
}

export default SucessPayment