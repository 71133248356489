import React, { useEffect, useContext } from "react";
import styled from "styled-components";
import CardConfirmPayment from "../../components/CardConfirmPayment";
import CardConfirmAdress from "../../components/CardConfirmAdress";
import CardConfirmAddressMobile from "../../components/CardConfirmAddressMobile";
import CardConfirmPaymentOptionMobile from "../../components/CardConfirmPaymentOptionMobile";

import CardConfirmPaymentOption from "../../components/CardConfirmPaymentOption";
import CardConfirmPaymentRequestInfo from "../../components/CardConfirmPaymentRequestInfo";
import HeaderPayment from "../../components/HeaderPayment";
import FooterPayment from "../../components/FooterPayment";
import Footer from "../../components/Footer";
import { useLocation } from 'react-router-dom';
import { CartContext } from "../../context/cart";
import io from 'socket.io-client';


const CardClean = styled.div`
    height: auto;
    
    @media screen and (max-width:820px){

        height: 30px;
        
    }

`
const ContainerData = styled.section`
    transition: all .2s;
    padding-top: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;

    @media screen and (max-width:820px){
        display: none;
        flex-direction: column;
        align-items: center;
        padding-top: 0rem;


    }

`
const ColumPrimary = styled.div`


    display: flex;
    flex-direction: column;
    height: 520px;

    @media screen and (max-width:820px){

        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        width: 98%;
        margin-right: 0rem;
        margin-left: 0rem;

        div{
        margin-right: 0rem!important;
        margin-bottom: 1.2rem;
    }   
        .card_primary{
            display: none;
        }

        .card_shipping{
            display: none;
        }

    }

    div{
        margin-right: 0.9rem;
        margin-bottom: 1.2rem;
    }

`
const ColumPrimaryMobile = styled.div`
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        width: 100%;
`
const ContainerDataMobile = styled.div`

    display: none;

    @media screen and (max-width:820px){
        display: flex;
    }
    

`

const ConfirmPayment = () => {
    const socket = io("https://api-tkg2-0.onrender.com/", {
        transports: ['websocket'], // Garante que o WebSocket seja usado
        reconnectionAttempts: 5,  // Tenta reconectar até 5 vezes
    });
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const page = queryParams.get('page');
    const { selectInfo } = useContext(CartContext)

    useEffect(() => {
        // Ouvir o evento 'new-order' que vem do servidor
        socket.on('new-order', (data) => {
            // Lógica para redirecionar o usuário para outra página
            window.location.href = '/payment/sucess';  // Redireciona para a página de confirmação
        });

        // Limpar o socket quando o componente for desmontado
        return () => {
            socket.off('new-order');  // Remove o ouvinte de eventos
        };
    }, []);

    return (
        <>
            <HeaderPayment />
            <ContainerData>
                <ColumPrimary>
                    <CardConfirmPayment name={"Informações pessoais"} index={1} />
                    <CardConfirmAdress name={"Endereço de entrega"} index={2} />
                </ColumPrimary>
                <ColumPrimary className="colum_secondary">
                    <CardConfirmPaymentRequestInfo name="Resumo do pedido" index={4} />

                </ColumPrimary>
                <ColumPrimary className="colum_request">
                    <CardConfirmPaymentOption name="Forma de pagamento" index={3} />
                </ColumPrimary>
            </ContainerData>

            <ContainerDataMobile>

                {selectInfo == 2

                    ?
                    <ColumPrimary>
                        <CardConfirmAddressMobile name={"Endereço de entrega"} index={2} />
                    </ColumPrimary>
                    :
                    selectInfo == 3

                        ?
                        <ColumPrimaryMobile>
                            <CardConfirmPaymentOption name="Forma de pagamento" index={3} />
                        </ColumPrimaryMobile>
                        :
                        <></>

                }


            </ContainerDataMobile>

            {selectInfo == 3

                ?
                <div style={{ height: "11.9rem" }}></div>
                :
                <CardClean />

            }

            <div className='container_page'>
                <Footer />

            </div>
        </>
    )
}

export default ConfirmPayment