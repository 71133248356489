import React from 'react'
import styled from 'styled-components'
import secureIcon from '../../images/svg/secureIcon.svg'
import companyIcon from '../../images/svg/companyIcon.001f5436a6e78d2342c801c1033bddae (2).svg'

const CompanyIconImage = styled.img`
    width: 90px;
    height: 90px;
    border-radius: 100px;
    margin-left: 9rem;

    @media screen and (max-width:820px){
        width: 70px;
        height:70px;
    }
        
`

const SecureIconImage = styled.img`
    width: 120px;
    height: 120px;
    border-radius: 100px;
    margin-right: 9rem;
`

const Header = styled.header`
    display: flex;

    justify-content: space-between;
    align-items: center;
    background-color: #F5F5F5;
    width: 100%;
    height: 100px;
    border-bottom: 2px solid #DBDADA;

    @media screen and (max-width:820px){
        transition: all .2s;
        height: 80px;

        img{
            margin-right: 0.9rem;
            margin-left: 0.9rem;
        }
    }

    
`
const HeaderPayment = () => {
    return(
        <Header>
            <CompanyIconImage src={companyIcon}/>
            <SecureIconImage src={secureIcon}/>
            
        </Header>
    )

}

export default HeaderPayment