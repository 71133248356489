import React, { createContext, useRef,useState } from 'react';
import { v4 as uuidv4 } from 'uuid';

export const CarrouselContext = createContext({});

function CarrouselProvider({ children }) {    
    
    const [page,setPage] = useState(1)
    const [session,setSession] = useState()
    const [sessionEngatesFixos,setSessionEngatesFixo] = useState(1)
    const [sessionEngatesRemoviveis,setSessionEngatesRemoviveis] = useState(1)
    const [acessorios,setAcessorios] = useState(1)


    const carrouselRef = useRef(null)
    const handleRightClick = () => {
        carrouselRef.current.scrollLeft += carrouselRef.current.offsetWidth
    }

    const handleLeftClick = () => {
        carrouselRef.current.scrollLeft -= carrouselRef.current.offsetWidth

    }


    return (
        <CarrouselContext.Provider value={{ carrouselRef, handleRightClick,handleLeftClick,page,setPage,setSession,session,sessionEngatesFixos,setSessionEngatesFixo,sessionEngatesRemoviveis,sessionEngatesRemoviveis,setSessionEngatesRemoviveis,setAcessorios,acessorios }}>
            {children}
        </CarrouselContext.Provider>
    );
}

export default CarrouselProvider;
