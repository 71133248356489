import React, { createContext, useRef, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import axios from 'axios';

export const PaymentContext = createContext({});

function PaymentProvider({ children }) {

    const [zipCode, setZipCode] = useState()
    const [address, setAddress] = useState()
    const [number, setNumber] = useState()
    const [neighBorhood, setNeighBorhood] = useState()
    const [city, setCity] = useState()
    const [state, setState] = useState()


    const getZipCode = async () => {
        try {
            const response = await axios.get(`https://viacep.com.br/ws/${zipCode}/json/`)
            if (response.status == 200) {
                // console.log(response.data)
                // setAddress(response.data.logradouro)
            }            
        } catch (error) {
            
        }
    }




    return (
        <PaymentContext.Provider value={{
            setZipCode,
            zipCode,
            setAddress,
            address,
            setNumber,
            number,
            setNeighBorhood,
            setCity,
            setState,
            getZipCode
        }}>
            {children}
        </PaymentContext.Provider>
    );
}

export default PaymentProvider;
