import React, { useEffect, useState,useContext } from 'react'

import { CartContext } from '../../context/cart'

import styled from 'styled-components'

const CardWrapper = styled.div`
    margin-top: 20px;
`
const DeleteIcon = styled.div`
        cursor: pointer;
        position: absolute;
        display: flex;
        left: -10px;
        top: -15px;
        justify-content: center;
        align-items: center;
        width: 28px;
        height: 28px;
        border-radius: 100px;
        background-color: white;
        box-shadow:  0 0 5px #a4a4a4dd;
`
const CardImage = styled.div`
    position: relative;
    svg{
        position: absolute;
        width: 16px;
        height: 16px;
    }
`
const Image = styled.img`
    border: 1px solid #D3D1D1;
    width: 118px;
    object-fit: contain;
    height: 118px;

    @media screen and (max-width:820px){
        width: 88px;
        height: 88px;
    }

`
const CardItemsPrimary = styled.div`
    display: flex;
    justify-content: space-between;
    align-items:center;

    p{
        font-family: "Lato", serif;
        font-weight: 700;
        font-style: normal;
        max-width: 520px;
        height:0px;
        margin-left: 30px;
        color: #515151;
    }


    .textSecondary{
        margin-top: 30px;
    }
    
    span{
        font-family: "Lato", serif;
        font-weight: 500;
        font-style: normal;
        font-size: 0.8rem;
        margin-left: 30px;
        
        font-size: 15px;
        color: #515151;

    }

    a{
        color: #515151;
        height: auto;
    }

    @media screen and (max-width:449px){
        
  
        p{
        font-family: "Lato", serif;
        font-weight: 700;
        font-style: normal;
        max-width: 520px;
        height: 35px;
        margin-left: 30px;
        color: #515151;
    }

    @media screen and (max-width:332px){
        p{
            height: 40px;
        }
    }


    }



`
const CadTextItemsPrimary = styled.div`
    display: flex;
    flex-direction: column;



`
const CardQuantity = styled.div`


    @media screen and (max-width:820px){
        display: none;
        
    }

    border-top:  0.1px solid #dedbdbaf;
    border-left: 0.1px solid #dedbdbaf;
    border-right:  0.1px solid #dedbdbaf;
    border-bottom:  0.1px solid #dedbdbaf;
    margin-top: 2rem;
    height: 33px;
    display: flex;
    align-items: center;
    width: 75px;
    margin-left: 5rem;
    border-radius: 5px;


    .quantity{
        width: 100px;
    }

    button{
        cursor: pointer;
        font-family: "Lato", serif;
        font-weight: 700;
        font-style: normal;
        background-color: transparent;
        border-left: white;
        height: 33px;
        width: 75px;
        border-radius: 2px;
        border:none;
        border-top:  0.1px solid #dedbdbaf;
        border-left: 0px solid #dedbdbaf;
        border-right:  0.1px solid #dedbdbaf;
        border-bottom:  0.1px solid #dedbdbaf;

        color:#909090
        ;

    }
    

`
const CardQuantityMobile = styled.div`


    @media screen and (max-width:820px){
        margin-top:0.5rem;
        display: flex;
        
    }

    border-top:  0.1px solid #dedbdbaf;
    border-left: 0.1px solid #dedbdbaf;
    border-right:  0.1px solid #dedbdbaf;
    border-bottom:  0.1px solid #dedbdbaf;
    margin-top: 0rem;
    height: 33px;
    display: flex;
    align-items: center;
    width: 79px;
    margin-left: 0rem;
    border-radius: 5px;


    .quantity{
        width: 100px;
    }

    button{
        cursor: pointer;
        font-family: "Lato", serif;
        font-weight: 700;
        font-style: normal;
        background-color: transparent;
        border-left: white;
        height: 33px;
        width: 75px;
        border-radius: 2px;
        border:none;
        border-top:  0.1px solid #dedbdbaf;
        border-left: 0px solid #dedbdbaf;
        border-right:  0.1px solid #dedbdbaf;
        border-bottom:  0.1px solid #dedbdbaf;

        color:#909090
        ;

    }
    

`
const Box = styled.div`
    
    display: flex;


`
const BoxPrice = styled.div`

    @media screen and (max-width:820px){
        display: none;
        
    }

`
const BoxPriceMobile = styled.div`

    @media screen and (max-width:820px){
        display: block;

        p{
            height: 5px;
        }

        .textPrimary{
            color: #515151;
            font-family: "Lato", serif;
            font-weight: 700;
            font-style: normal;
        }

        .textSecondary{
            font-size: 11px;
            color: #909090;
            font-family: "Lato", serif;
            font-weight: 500;
            font-style: normal;
        }
        
    }

`
const BoxMobile = styled.div`

    display: none;

    @media screen and (max-width:820px){
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    border-bottom: 5px solid #EDEDED;
    
    div{
        margin-bottom: 20px;
    }
        
    }


`



const CardItem = ({ image, description, sku, price, category, index,quantity,product_id }) => {

    const {handleUpdateQuantity,handleRemoveItem} = useContext(CartContext)

    const [count, setCount] = useState(1)
    const [newPrice,setNewPrice] = useState()

    const handlePlus = () => {
        const formatedPrice = String(price).replace(",", ".");
        setCount((prevCount) => {
            const updatedCount = prevCount + 1;
            handleUpdateQuantity(index,updatedCount)
            setNewPrice(updatedCount * Number(formatedPrice));
            return updatedCount;
        });
    };

    const handleMinus = () => {
        const formatedPrice = String(price).replace(",", ".");
        setCount((prevCount) => {
            const updatedCount = prevCount - 1;
            handleUpdateQuantity(index,updatedCount)

            if (updatedCount > 0) {
                setNewPrice(updatedCount * Number(formatedPrice));
                return updatedCount;
            }
            return prevCount; // Evita contagem negativa
        });
    };

    useEffect(() => {
        setNewPrice(price)
    },[price])

    useEffect(() => {

        if(quantity){
            setNewPrice(Number(quantity) * Number(price))
            setCount(quantity)
        }

    },[quantity])



    return (
        <CardWrapper>
            <CardItemsPrimary>

                <Box>
                    <CardImage>

                        <DeleteIcon onClick={() => handleRemoveItem(index)}>
                            <svg width="24" height="26" viewBox="0 0 24 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M8.85283 10.3216V19.9647C8.85283 20.2656 8.61845 20.5 8.31752 20.5H7.24595C6.94502 20.5 6.71064 20.2656 6.71064 19.9647V10.3216C6.71064 10.0206 6.94502 9.78627 7.24595 9.78627H8.31752C8.61845 9.78627 8.85283 10.0206 8.85283 10.3216ZM13.1381 10.3216V19.9647C13.1381 20.2656 12.9038 20.5 12.6028 20.5H11.5313C11.2303 20.5 10.996 20.2656 10.996 19.9647V10.3216C10.996 10.0206 11.2303 9.78627 11.5313 9.78627H12.6028C12.9038 9.78627 13.1381 10.0206 13.1381 10.3216ZM17.4244 10.3216V19.9647C17.4244 20.2656 17.19 20.5 16.8891 20.5H15.8175C15.5166 20.5 15.2822 20.2656 15.2822 19.9647V10.3216C15.2822 10.0206 15.5166 9.78627 15.8175 9.78627H16.8891C17.19 9.78627 17.4244 10.0206 17.4244 10.3216ZM19.5666 22.4416V6.57064H4.56658V22.4416C4.56658 23.245 5.01846 23.7138 5.10189 23.7138H19.0303C19.1138 23.7138 19.5666 23.245 19.5666 22.4416ZM8.31658 4.42846H15.8166L15.0131 2.47002C14.9625 2.40346 14.8125 2.30221 14.7281 2.28627H9.42095C9.32064 2.30314 9.18658 2.40346 9.13595 2.47002L8.31658 4.42846ZM23.8528 4.96471V6.03627C23.8528 6.33721 23.6185 6.57158 23.3175 6.57158H21.7106V22.4425C21.7106 24.2838 20.505 25.8578 19.0322 25.8578H5.10377C3.63095 25.8578 2.42533 24.3513 2.42533 22.51V6.57252H0.818455C0.517517 6.57252 0.283142 6.33814 0.283142 6.03721V4.96564C0.283142 4.66471 0.517517 4.43033 0.818455 4.43033H5.99158L7.16346 1.63471C7.49814 0.814395 8.50314 0.14502 9.39002 0.14502H14.7469C15.6338 0.14502 16.6388 0.814395 16.9735 1.63471L18.1453 4.43033H23.3185C23.6194 4.43033 23.8538 4.66471 23.8538 4.96564L23.8528 4.96471Z" fill="#777777" />
                            </svg>
                        </DeleteIcon>

                        <Image src={image} />
                    </CardImage>
                    <CadTextItemsPrimary>
                        <a href={`product/?id=${product_id}`}><p>{description}</p></a>
                        <span className='textSecondary'>Ref. {sku}</span>
                        <span style={{ height: "0px" }}>Marca: {category}</span>
                    </CadTextItemsPrimary>

                    <CardQuantity>
                        <button onClick={() => handleMinus()}>-</button>
                        <button className='quantity'>{count}</button>
                        <button onClick={() => handlePlus()}>+</button>

                    </CardQuantity>
                </Box>
                <BoxPrice>
                    <p>R$ {Number(newPrice).toLocaleString("pt-BR", { minimumFractionDigits: 2 })}</p>
                </BoxPrice>
            </CardItemsPrimary>
            <BoxMobile className='box_mobile'>
                <CardQuantityMobile>
                    <button onClick={() => handleMinus()}>-</button>
                    <button className='quantity'>{count}</button>
                    <button onClick={() => handlePlus()}>+</button>
                </CardQuantityMobile>

                <BoxPriceMobile>
                <p>R$ {Number(newPrice).toLocaleString("pt-BR", { minimumFractionDigits: 2 })}</p>
                <p className='textSecondary'></p>

                </BoxPriceMobile>
            </BoxMobile>

        </CardWrapper>

    )
}

export default CardItem