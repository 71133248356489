import React,{useEffect,useState} from 'react'
import styled from 'styled-components'
import Header from '../../components/Header'
import HeaderMobile from '../../components/HeaderMobile'
import HeaderSecondary from '../../components/HeaderSecondary'
import Container from '../../components/Container';
import api from '../../services/api'

import { Editor, EditorState, convertFromRaw } from 'draft-js';

const CardWrapper = styled.section`
`
const Row = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 2rem;
    width: 1320px;
    padding-left: 2.7rem;

    .primary{
        font-size: 1.2rem;
        font-family: "Poppins", system-ui;
        font-weight: 500;
    }

    .secondary{
        font-size: 1.0rem;
        line-height: 30px;
        color: #656565;
        font-family: "Poppins", system-ui;
        font-weight: 500;
    }

    @media screen and (max-width:820px){
            padding: 0.9rem;
            width: 100%;
            flex-direction: column;
            align-items: center;

            p{
                text-align: center;
            }
        
    }

`

const AboutPrivacy = () => {

    const [editorState, setEditorState] = useState(() => EditorState.createEmpty()); // Estado inicial vazio do Editor

    const get = async () => {
        const response = await api.get("/client/get/pages/2")
        console.log(response.data)
        if (response.status == 200) {
            if(response.data[0].description != ""){
                const contentState = convertFromRaw(JSON.parse(response.data[0].description));
                setEditorState(EditorState.createWithContent(contentState));
            }


        }

    }

    useEffect(() => {
        get()
    }, [])
    return (
        <Container>
            <Header />
            <HeaderMobile />
            <HeaderSecondary />
            <Row>
                <Editor
                    readOnly={true}
                    editorState={editorState}
                    onChange={() => <></>}
                />
            </Row>

        </Container>
    )
}
export default AboutPrivacy