import React, { useState, useContext, useEffect } from 'react'
import Cards from 'react-credit-cards-2';
import styled from 'styled-components'
import cardIcon from '../../images/svg/creditCardIcon.svg'
import PixIcon from '../../images/svg/icons8-foto.svg'
import 'react-credit-cards-2/dist/es/styles-compiled.css';
import animation from '../../images/animation/creditCard.json'
import InputShipping from "../InputShipping";
import creditCardIcon from '../../images/svg/creditCardInput.svg'
import lockIconPayment from '../../images/svg/lockIconPayment.svg'
import api from '../../services/api';
import { useNavigate } from 'react-router-dom';
import calendarIconPayment from '../../images/svg/calendarIconPayment.svg'
import installmentIcon from '../../images/svg/installmentIcon.svg'

import { UserContext } from '../../context/user';
import { CartContext } from '../../context/cart';
import { toast } from 'react-toastify';
import { cpf } from 'cpf-cnpj-validator';
import cpfIcon from '../../images/svg/cpfIconInput.svg'
import { CopyToClipboard } from 'react-copy-to-clipboard';
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

import SelectShipping from '../SelectShipping';


var valid = require("card-validator");

const Card = styled.div`
  transition: all 0.3s;
  display: flex;
  flex-direction: column;
  width: 99%;
  width: ${(props) => (props.active ? " auto" : "250px")};
  background-color:${(props) => (props.active ? "white" : " #fbfbfb")};
  border: 1px solid #e0dcdc;

  @media screen and (max-width:820px){
    margin-top: 0rem;
    border: none;
    padding:0.5rem;
    width: 99%;
  }

`;
const CardHeader = styled.div`
    display:flex;
    height:30px;
    padding-top: 0.9rem;
    padding-left: 0.5rem;
    justify-content: space-between;
    
    a{
        text-decoration: solid !important;
        cursor: pointer;
    }

    @media screen and (max-width:820px){
        margin-bottom: 1.0rem;
        height:40px;

    }
    
`
const Row = styled.div`
    display: flex;

    p{
    font-size: ${(props) => (props.active ? " 1rem" : "1rem")};
    font-family: "Lato", serif;
    font-weight: 700;
    font-style: normal;
  }

  .textType{
      position: relative;
      margin-left:${(props) => (props.active ? "  -0.3rem" : " 0.5rem")};
      top:${(props) => (props.active ? "" : " 1px")};

    }

    @media screen and (max-width:820px) {
        .textType{
      position: relative;
      margin-left:${(props) => (props.active ? "  0.3rem" : " 0rem")};
      top:${(props) => (props.active ? "" : " 1px")};

    }
    }


`
const CardIndex = styled.div` 
  transition: all .2s;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 100px;
  width: 25px;
  height: 25px;
  padding-top: 0.9rem;
  background-color: ${(props) => (props.active ? "#008d24" : "white")};

  border:${(props) => (props.active ? "none" : "2px solid #cecccc;")};

  p {
    font-size:${(props) => (props.active ? "0.9rem" : "0.8rem")};
    font-family: "Lato", serif;
    font-weight: 700;
    font-style: normal;
    color:${(props) => (props.active ? "white" : "#cecccc")} ;
  }
`;
const CardBody = styled.div`
   padding-left: 0.9rem;
   display:${(props) => (props.active ? "auto" : "none")} ;

   .textPrimary{
    margin-top: 0.9rem;
    font-size: 01rem;
    font-family: "Lato", serif;
    font-weight: 500;
    font-style: normal;
   }

   @media screen and (max-width:820px){
    padding-left: 0rem;

    .textPrimary{
        display: none;
    }
   }



`
const CardPayment = styled.div`
`
const CardPaymentHeader = styled.div`
`
const Option = styled.div`
    cursor:pointer;
    border: 1px solid #dddddd;
    padding-left:0.3rem;
    display: flex;
    background-color: red;
    align-items: center;
    width: 100%;
    height: 53px;
    background-color: white;
    box-shadow: 0 2px 0 #dadada;
    margin-bottom: 1rem;
    
    p{
        font-size: 0.8rem;
        font-family: "Lato", serif;
        font-weight: 700;
        color:#6e6e6e;
        position: relative;
        top: 9px;
        left: 9px;
    }

    img{
        width: 21px;
        height: 21px;
        }

        .textInstallment{
            font-size: 0.8rem;
            margin-left: 5.2rem;
        }

        @media screen and (max-width:820px){

            .textInstallment{
            display: none;
            right: 0;
            font-size: 0.8rem;
        }
            
        }


`
const CardButtons = styled.div`

    margin-top: 2rem;
    padding-left: 9rem;
    padding-right: 9rem;
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: center;
    width: 100%;

    button{
        width: 262.5px;
    }

@media screen and (max-width:820px){
        display:block;
        padding-left: 0.9rem;
        padding-right: 0.9rem;

        button{
            margin-top: 0.9rem;
            width: 100%;
        }

    }
`
const ButtonAdd = styled.button`
    border-radius: 2px;
    width: 100%;
    height: 49px;
    color:#414040;
    background-color: #FFFFFF;
    border: 1px solid #827c7c;

`

const ButtonContinue = styled.button`
    
    border-radius: 2px;
    font-family: "Lato", serif;
    font-weight: 500;
    color: #FFFFFF;
    width: 100%;
    height: 35px;
    background-color: #000000;
    border: none;
    transition: all 0.1s ease-in-out;

    :active {
        top: 13px;
        box-shadow: 0 2px 0px #027a02;
    }

    @media screen and (max-width: 820px) {
        margin-bottom: 1rem;
    }
`;
const ContainerCardCreditCard = styled.div`

    @media screen and (max-width:820px){
     
        form{
            margin-top: 1.9rem;
            margin-bottom: 1rem;
        }

        form > div{
        margin-bottom: 1rem;
        }        
    }


`
const ContainerQrCode = styled.div`

        display:flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-bottom: 1.2rem;

    .textPix{
        width: 320px;
        font-family: "Lato", serif;
        font-weight: 600;
        font-style: normal;
        text-align: center;

    }
    
    @media screen and (max-width:820px){
        display:flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-bottom: 1.2rem;

    .textPix{
        font-family: "Lato", serif;
        font-weight: 600;
        font-style: normal;
        text-align: center;

    }
    }
    


`
const QrCode = styled.img`
    width: 220px;
    height: 220px;
`
const SkeletonQrCode = styled.div`
    width: 220px;
    height: 220px;

`

const Flex = styled.div`
    display: flex;
    align-items: center;
`



const CardConfirmPaymentOption = ({ name, index, id }) => {
    const navigate = useNavigate()
    const { setZipCode, setStreet, setLocation, zipCode, street, location, setNumber, number, state, zipCodeErr,selectOption,setSelectOption } = useContext(UserContext)
    const { setSelectInfo, selectInfo, price } = useContext(CartContext)
    const [qrCode, setQrCode] = useState()
    const [cpfUser, setCpfUser] = useState()
    const [installment, setInstallment] = useState(1)
    const [statusZipCode, setStatusZipCode] = useState()
    const [cardErr,setCardErr] = useState()
    const [expiryErr,setExpiryErr] = useState()
    const [nameErr,setNameErr] = useState()
    const [cvvErr,setCvvErr] = useState()
    const [cpfErr,setCpfErr] = useState()

    const [stateCard, setStateCard] = useState({
        number: '',
        expiry: '',
        cvc: '',
        name: '',
        focus: '',
    });

    const handleInputChange = (evt) => {
        const { name, value } = evt.target;
        console.log(name)
        console.log(value)
        setStateCard((prev) => ({ ...prev, [name]: value }));
    }
    const handleInputFocus = (evt) => {
        setStateCard((prev) => ({ ...prev, focus: evt.target.name }));
    }
    const getQrCode = async () => {

        const response = await api.post("/payment/storage/pix", {
            price: "",
            zipCode: zipCode,
            street: street,
            number: number,
            complement: "",
            state: state,
            city: location,
            cart: JSON.parse(localStorage.getItem("@LOCAL_CART")),
            mail: localStorage.getItem('@USER_MAIL'),
        })

        if (response.status == 200) {
            console.log(response.data)
            setQrCode(response.data)
        }

    }
    const handleCreditCard = async () => {
        var numberValidation = valid.number(stateCard.number);

        if (!numberValidation.isValid) {
            return toast.error("Número do cartão inválido!", {
                autoClose: 1500
            })
        }

        if (!stateCard.number) {
            return toast.error("Informe um número do cartão", {
                autoClose: 1500
            })
        }
        if (!stateCard.expiry) {
            return toast.error("Informe uma data de validade", {
                autoClose: 1500
            })
        }

        if (String(stateCard.expiry).length < 5) {
            return toast.error("Data de validade inválida!")
        }

        if (!stateCard.name) {
            return toast.error("Informe o nome do cartão", {
                autoClose: 1500
            })
        }

        if (String(stateCard.cvc).length < 3) {
            return toast.error("Código de segurança inválido!", {
                autoClose: 1500
            })
        }
        if (!stateCard.cvc) {
            return toast.error("Informe um código de segurança", {
                autoClose: 1500
            })
        }

        const cpfIsValid = cpf.isValid(cpfUser)

        if (!cpfIsValid) {
            return toast.error("CPF inválido!", {
                autoClose: 1500
            })
        }

        handleBtnPayment()
    }
    const handleBtnPayment = async () => {
        const loadingToastId = toast.loading("Processando o pedido...");


        const formatedExpiration = String(stateCard.expiry).split("")

        const [month, year] = stateCard.expiry.split("/");
        // Formatação para o backend
        const formattedExpirationSecondary = `20${year}`; // Adicionando o prefixo "20" ao ano


        const response = await api.post("payment/storage/",
            {

                "city": location,
                "cpfNumber": cpfUser,
                "street": street,
                "number": number,
                "complement": "",
                "state": state,
                "zipCode": zipCode,
                "payment_id": "1",
                "mail": localStorage.getItem('@USER_MAIL'),
                "cart": JSON.parse(localStorage.getItem("@LOCAL_CART")),
                "cpf": cpfUser,
                "payment_option": 1,
                "card_number": String(stateCard.number).replace(" ", "").replace(" ", "").replace(" ", "").replace(" ", ""),
                "expiration_month": `${formatedExpiration[0]}${formatedExpiration[1]}`,
                "expiration_year": `${formattedExpirationSecondary}`,
                "security_code": stateCard.cvc,
                "cardholder": stateCard.name,
                "price": "",
                "installments": installment,
                "status": 1,
            }

        )

        if (response.status == 200) {

            localStorage.removeItem("@LOCAL_CART")
            window.location.href = "/payment/sucess"
        } else {
            return toast.update(loadingToastId, {
                render: response.data.message,
                type: "error",
                isLoading: false,
                autoClose: 1000
            });

        }


    }
    const copyPix = async () => {
        toast.success("Chave pix copiada com sucesso!", {
            autoClose: 1500
        })

    };
    const handleValidateAdress = (option) => {

        if (zipCodeErr) {
            return toast.error("Cep inválido!", {
                autoClose: 1500
            })
        }

        if (!zipCode) {
            return toast.error("Informe um cep", {
                autoClose: 1500
            })
        }

        if (!number) {
            return toast.error("Informe um número", {
                autoClose: 1500
            })
        }
        setSelectOption(option)
        setSelectInfo(3)
    }

    useEffect(() => {
        if (stateCard.number) {
            var numberValidation = valid.number(stateCard.number);
    
            if (!numberValidation.isValid) {
                setCardErr("número do cartão inválido!")
            }else{
                setCardErr()
            }
        }

        if (stateCard.expiry) {
            
            if(!stateCard.expiry){
                setExpiryErr("data de validade inválida!")
            }

            if (String(stateCard.expiry).length < 5) {
                setExpiryErr("data de validade inválida!")
            }else{
                setExpiryErr()
            }
        
        }

        if (stateCard.name) {
            
            if(!stateCard.name){
                setNameErr("informe o nome do cartão!")
            }else{
                setNameErr()
            }

            
            if (String(stateCard.name).length < 10) {
                setNameErr(" nome do cartão inválido")
            }else{
                setNameErr()
            }
        
        }

        
        if (stateCard.cvc) {
            
            if(!stateCard.cvc){
                setCvvErr("informe o cvv do cartão!")
            }else{
                setCvvErr()
            }

            
            if (String(stateCard.cvc).length < 3) {
                setCvvErr("cvv inválido!")
            }else{
                setCvvErr()
            }
            
        
        }

        if(cpfUser){

            
        const cpfIsValid = cpf.isValid(cpfUser)

        if (!cpfIsValid) {
            setCpfErr("cpf inválido!")
        }else{
            setCpfErr()
        }

        }

    }, [stateCard.number,stateCard.expiry,stateCard.name,stateCard.cvc,cpfUser]);
    

    return (

        <Card className='card_shipping' active={selectInfo == 3 ? true : false} >
            <CardHeader>
                <Row active={selectInfo == 3 ? true : false}>
                    <CardIndex active={selectInfo == 3 ? true : false}>
                        <p>{4}</p>

                    </CardIndex>
                    <p className='textType'>{name}</p>
                </Row>
            </CardHeader>

            <CardBody active={selectInfo == 3 ? true : false}>

                {selectOption == 1

                    ?
                    <ContainerCardCreditCard>
                        {stateCard

                            ?
                            <Cards

                                number={stateCard.number}
                                expiry={stateCard.expiry}
                                cvc={stateCard.cvc}
                                name={stateCard.name}
                                focused={stateCard.focus}
                                formData=''
                                issuer=''

                            />

                            :
                            <></>

                        }
                        <form action='' >
                            <InputShipping name="Número cartão" mask={"9999 9999 9999 9999"} onFocus={handleInputFocus} nameInput={"number"} onChange={handleInputChange} value={state.number} image={creditCardIcon} messageErr={cardErr}  err={cardErr ? true : false} />
                            <InputShipping name="Validade (MM/AA)" mask={"99/99"} onFocus={handleInputFocus} nameInput={"expiry"} onChange={handleInputChange} value={state.expiry} image={calendarIconPayment} messageErr={expiryErr} err={expiryErr ? true : false} />
                            <InputShipping name="Nome e sobrenome no cartão" onFocus={handleInputFocus} nameInput={"name"} onChange={handleInputChange} value={state.name} image={creditCardIcon} messageErr={nameErr} err={nameErr ? true : false} />
                            <InputShipping name="Código de segurança " mask={"999"} onFocus={handleInputFocus} nameInput={"cvc"} onChange={handleInputChange} value={state.cvc} image={lockIconPayment} messageErr={cvvErr} err={cvvErr ? true : false} />
                            <InputShipping name="CPF " mask={"999.999.999-99"} nameInput={"cvc"} onChange={(e) => setCpfUser(e.target.value)} value={cpfUser} image={cpfIcon} err={cpfErr ? true : false} messageErr={cpfErr} />
                            <SelectShipping onChange={(e) => { setInstallment(e.target.value) }} dataValue={price} image={installmentIcon} />

                        </form>
                        <ButtonContinue onClick={() => handleCreditCard()} >Finalizar pedido</ButtonContinue>

                    </ContainerCardCreditCard>
                    :
                    selectOption == 2

                        ?
                        <ContainerQrCode>
                            {!qrCode?.qr_code_base64

                                ?
                                <SkeletonQrCode>
                                    <Skeleton style={{ height: "100%" }} />
                                </SkeletonQrCode>
                                :
                                <>
                                    <QrCode src={`data:image/jpeg;base64,${qrCode?.qr_code_base64}`} />
                                    <p className='textPix'>Quando o pagamento for aprovado, você será redirecionado automaticamente.</p>
                                    <CopyToClipboard text={qrCode?.qr_code} onCopy={copyPix}>
                                        <ButtonContinue>
                                            Copiar código PIX
                                        </ButtonContinue>

                                    </CopyToClipboard>
                                </>

                            }

                        </ContainerQrCode>
                        :
                        <></>
                }

                {selectOption == undefined

                    ?
                    <>
                        <p className='textPrimary'>Selecione uma forma de pagamento para <br /> finalizar seu pedido:</p>
                        <CardPayment>
                            <CardPaymentHeader>

                                <Option onClick={() => { handleValidateAdress(1) }}>
                                    <img src={cardIcon} />
                                    <p>Cartão de Crédito <br />Parcele em até 12x</p>
                                    <p className='textInstallment'>até 12x</p>

                                </Option>

                                {zipCode

                                    ?
                                    <Option onClick={() => {handleValidateAdress(2); getQrCode() }}>
                                        <img src={PixIcon} />
                                        <p>Pix - Mercado Livre<br />Pague á vista</p>
                                        <p className='textInstallment'>à vista</p>
                                    </Option>
                                    :

                                    <Option onClick={() => {handleValidateAdress(2); getQrCode()}}>
                                        <img src={PixIcon} />
                                        <p>Pix - Mercado Livre<br />Pague á vista</p>
                                        <p className='textInstallment'>à vista</p>
                                    </Option>

                                }

                            </CardPaymentHeader>
                        </CardPayment>
                    </>
                    :
                    selectOption == 1

                        ?
                        <Option onClick={() => {handleValidateAdress(2); getQrCode() }}>
                            <img src={PixIcon} />
                            <p>Pix - Mercado Livre<br />Pague á vista</p>
                            <p className='textInstallment'>à vista</p>
                        </Option>
                        :

                        <Option onClick={() => {setSelectOption(1)}}>
                            <img src={cardIcon} />
                            <p>Cartão de Crédito <br />Parcele em até 12x</p>

                            <p className='textInstallment'>até 12x</p>
                        </Option>
                }

            </CardBody>
        </Card>
    )
}

export default CardConfirmPaymentOption