import React, { useState, useContext, useEffect } from "react";
import { CartContext } from "../../context/cart";
import styled from "styled-components";
import InputShipping from "../InputShipping";
import mailIcon from '../../images/svg/mailIcon.svg'
import userIcon from '../../images/svg/userInputIcon.svg'
import cpfIconInput from '../../images/svg/cpfIconInput.svg'
import phoneIconInput from '../../images/svg/phoneIconInput.svg'
import api from "../../services/api";

const Card = styled.div`
  transition: all 0.3s;
  display: flex;
  flex-direction: column;
  width: 359px;
  box-shadow: 0 0 2px #bbbbbbdd;

  height: ${(props) => (props.active ? "auto" : "50px")};
  background-color:${(props) => (props.active ? "#fbfbfb" : "white")};
  border: 1px solid #e0dcdc;
  border-radius: 5px;
`;

const CardHeader = styled.div`
  display: flex;
  padding-top: 0.9rem;
  padding-left: 0.5rem;
  height: 60px;
  justify-content: space-between;

  a {
    text-decoration: solid !important;
    cursor: pointer;
  }
`;

const CardBody = styled.div`
  padding-left: 0.5rem;

`

const Row = styled.div`
  display: flex;

  p{
    font-family: "Lato", serif;
    font-weight: 700;
    font-style: normal;
  }

`;


const CardIndex = styled.div` 
  transition: all .2s;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 100px;
  width: 25px;
  height: 25px;
  padding-top: 0.9rem;
  background-color: ${(props) => (props.active ? "#008d24" : "white")};

  border:${(props) => (props.active ? "none" : "2px solid #cecccc;")};

  p {
    font-size:${(props) => (props.active ? "0.9rem" : "0.8rem")};
    font-family: "Lato", serif;
    font-weight: 700;
    font-style: normal;
    color:${(props) => (props.active ? "white" : "#cecccc")} ;
  }
`;

const CardConfirmPayment = ({ name, index, id, activeId, setActiveId, idSelect, onClick }) => {
  const { setSelectInfo, selectInfo } = useContext(CartContext)
  const [data,setData] = useState([])

  const getData = async () => {
    const response = await api.get(`client/get/by/id`);
    setData(response.data)
  }


  useEffect(() => {

    getData()
  },[])
  return (
    <Card className="card_primary" active={selectInfo == 1 ? true : false}>
      <CardHeader>
        <Row>
          <CardIndex active={selectInfo == 1 ? true : false}>
            <p>{index}</p>
          </CardIndex>
          <p>{name}</p>
        </Row>
        <a onClick={() => setSelectInfo(1)}>Exibir</a>
      </CardHeader>
      {selectInfo == 1

        ?
        <CardBody>
          <InputShipping name="E-mail (seu@email.com.br)" value={data[0]?.mail} image={mailIcon} />
          <InputShipping name="Nome completo" value={data[0]?.name} image={userIcon} />
          <InputShipping name="CPF (123.456.789-10)" value={data[0]?.cpf} image={cpfIconInput} />
          <InputShipping name="Telefone celular (11 99999-99999)" value={data[0]?.phone} image={phoneIconInput} />

        </CardBody>
        :

        <></>
      }

    </Card>
  );
};


export default CardConfirmPayment;
